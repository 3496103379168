import pt from './default.json';
import en from './default.en.json';
import es from './default.es.json';

//Pages
import homePt from '../../pages/Home/locales/default.json';
import homeEn from '../../pages/Home/locales/default.en.json';
import homeEs from '../../pages/Home/locales/default.es.json';

import aboutusPt from '../../pages/AboutUs/locales/default.json';
import aboutusEn from '../../pages/AboutUs/locales/default.en.json';
import aboutusEs from '../../pages/AboutUs/locales/default.es.json';

import affiliatesPt from '../../pages/Affiliates/locales/default.json';
import affiliatesEn from '../../pages/Affiliates/locales/default.en.json';
import affiliatesEs from '../../pages/Affiliates/locales/default.es.json';

import contactPt from '../../pages/ContactUs/locales/default.json';
import contactEn from '../../pages/ContactUs/locales/default.en.json';
import contactEs from '../../pages/ContactUs/locales/default.es.json';

import cookiesPt from '../../pages/Cookies/locales/default.json';
import cookiesEn from '../../pages/Cookies/locales/default.en.json';
import cookiesEs from '../../pages/Cookies/locales/default.es.json';

import commonQuestionsPt from '../../pages/CommonQuestions/locales/default.json';
import commonQuestionsEn from '../../pages/CommonQuestions/locales/default.en.json';
import commonQuestionsEs from '../../pages/CommonQuestions/locales/default.es.json';

import privacyPt from '../../pages/PrivacyNotice/locales/default.json';
import privacyEn from '../../pages/PrivacyNotice/locales/default.en.json';
import privacyEs from '../../pages/PrivacyNotice/locales/default.es.json';

import termsOfPurchasePt from '../../pages/TermsOfPurchase/locales/default.json';
import termsOfPurchaseEn from '../../pages/TermsOfPurchase/locales/default.en.json';
import termsOfPurchaseEs from '../../pages/TermsOfPurchase/locales/default.es.json';

import termsOfUsePt from '../../pages/TermsOfUse/locales/default.json';
import termsOfUseEn from '../../pages/TermsOfUse/locales/default.en.json';
import termsOfUseEs from '../../pages/TermsOfUse/locales/default.es.json';

import tourPt from '../../pages/Tour/locales/default.json';
import tourEn from '../../pages/Tour/locales/default.en.json';
import tourEs from '../../pages/Tour/locales/default.es.json';

import tourListPt from '../../pages/TourList/locales/default.json';
import tourListEn from '../../pages/TourList/locales/default.en.json';
import tourListEs from '../../pages/TourList/locales/default.es.json';

import transferPt from '../../pages/TransferList/locales/default.json';
import transferEn from '../../pages/TransferList/locales/default.en.json';
import transferEs from '../../pages/TransferList/locales/default.es.json';

// import notFoundPt from '../../pages/NotFound/locales/default.json';
// import notFoundEn from '../../pages/NotFound/locales/default.en.json';
// import notFoundEs from '../../pages/NotFound/locales/default.es.json';

// import loginPt from '../../pages/Login/locales/default.json';
// import loginEn from '../../pages/Login/locales/default.en.json';
// import loginEs from '../../pages/Login/locales/default.es.json';

//Components
import footerPt from '../../components/Footer/locales/default.json';
import footerEn from '../../components/Footer/locales/default.en.json';
import footerEs from '../../components/Footer/locales/default.es.json';

import cookieAlertPt from '../../components/CookieAlert/locales/default.json';
import cookieAlertEn from '../../components/CookieAlert/locales/default.en.json';
import cookieAlertEs from '../../components/CookieAlert/locales/default.es.json';

import navHeaderPt from '../../components/Header/NavHeader/locales/default.json';
import navHeaderEn from '../../components/Header/NavHeader/locales/default.en.json';
import navHeaderEs from '../../components/Header/NavHeader/locales/default.es.json';

import cartHeaderPt from '../../components/Cart/locales/default.json';
import cartHeaderEn from '../../components/Cart/locales/default.en.json';
import cartHeaderEs from '../../components/Cart/locales/default.es.json';

import newUserPt from '../../pages/RegisterUser/locales/default.json';
import newUserEn from '../../pages/RegisterUser/locales/default.en.json';
import newUserEs from '../../pages/RegisterUser/locales/default.es.json';

import tabSearchPt from '../../pages/Home/components/TabSearchs/locales/default.json';
import tabSearchEn from '../../pages/Home/components/TabSearchs/locales/default.en.json';
import tabSearchEs from '../../pages/Home/components/TabSearchs/locales/default.es.json';

import productOptionsPt from '../../pages/Tour/components/ProductOptions/locales/default.json';
import productOptionsEn from '../../pages/Tour/components/ProductOptions/locales/default.en.json';
import productOptionsEs from '../../pages/Tour/components/ProductOptions/locales/default.es.json';

// import partnersPt from '../../components/Partners/locales/default.json';
// import partnersEn from '../../components/Partners/locales/default.en.json';
// import partnersEs from '../../components/Partners/locales/default.es.json';

// import newsletterPt from '../../components/Newsletter/locales/default.json';
// import newsletterEn from '../../components/Newsletter/locales/default.en.json';
// import newsletterEs from '../../components/Newsletter/locales/default.es.json';

import CheckoutStepsPt from '../../pages/Checkout/components/CheckoutSteps/locales/default.json';
import CheckoutStepsEn from '../../pages/Checkout/components/CheckoutSteps/locales/default.en.json';
import CheckoutStepsEs from '../../pages/Checkout/components/CheckoutSteps/locales/default.es.json';

import CheckoutCardsPt from '../../pages/Checkout/components/CheckoutSteps/CheckoutCards/locales/default.json';
import CheckoutCardsEn from '../../pages/Checkout/components/CheckoutSteps/CheckoutCards/locales/default.en.json';
import CheckoutCardsEs from '../../pages/Checkout/components/CheckoutSteps/CheckoutCards/locales/default.es.json';

import ProductsCarrouselPt from '../../pages/Home/components/ProductsCarrousel/locales/default.json';
import ProductsCarrouselEn from '../../pages/Home/components/ProductsCarrousel/locales/default.en.json';
import ProductsCarrouselEs from '../../pages/Home/components/ProductsCarrousel/locales/default.es.json';

import ProductsReviewsPt from '../../pages/Tour/components/ProductsReviews/locales/default.json';
import ProductsReviewsEn from '../../pages/Tour/components/ProductsReviews/locales/default.en.json';
import ProductsReviewsEs from '../../pages/Tour/components/ProductsReviews/locales/default.es.json';

// import PrivacyTermsPt from '../../components/PrivacyTermsNotice/locales/default.json';
// import PrivacyTermsEn from '../../components/PrivacyTermsNotice/locales/default.en.json';
// import PrivacyTermsEs from '../../components/PrivacyTermsNotice/locales/default.es.json';

import modalPaymentPt from '../../components/Modal/ModalPayment/components/ModalContentPayment/locales/default.json';
import modalPaymentEn from '../../components/Modal/ModalPayment/components/ModalContentPayment/locales/default.en.json';
import modalPaymentEs from '../../components/Modal/ModalPayment/components/ModalContentPayment/locales/default.es.json';

import ProfilePt from '../../pages/Profile/locales/default.json';
import ProfileEn from '../../pages/Profile/locales/default.en.json';
import ProfileEs from '../../pages/Profile/locales/default.es.json';

import PrivacyTermsNoticePt from '../../components/PrivacyTermsNotice/locales/default.json';
import PrivacyTermsNoticeEn from '../../components/PrivacyTermsNotice/locales/default.en.json';
import PrivacyTermsNoticeEs from '../../components/PrivacyTermsNotice/locales/default.es.json';

import ProfilePasswordPt from '../../pages/ProfilePassword/locales/default.json';
import ProfilePasswordEn from '../../pages/ProfilePassword/locales/default.en.json';
import ProfilePasswordEs from '../../pages/ProfilePassword/locales/default.es.json';

import MyActivitiesPt from '../../pages/MyActivities/locales/default.json';
import MyActivitiesEn from '../../pages/MyActivities/locales/default.en.json';
import MyActivitiesEs from '../../pages/MyActivities/locales/default.es.json';

import MyReservationsPt from '../../pages/MyReservations/locales/default.json';
import MyReservationsEn from '../../pages/MyReservations/locales/default.en.json';
import MyReservationsEs from '../../pages/MyReservations/locales/default.es.json';

import ModalConfirmPt from '../../components/ModalConfirm/locales/default.json';
import ModalConfirmEn from '../../components/ModalConfirm/locales/default.en.json';
import ModalConfirmEs from '../../components/ModalConfirm/locales/default.es.json';

import ModalAlertPt from '../../components/Modal/ModalAlert/locales/default.json';
import ModalAlertEn from '../../components/Modal/ModalAlert/locales/default.en.json';
import ModalAlertEs from '../../components/Modal/ModalAlert/locales/default.es.json';

import TopHeaderPt from '../../components/TopHeader/locales/default.json';
import TopHeaderEn from '../../components/TopHeader/locales/default.en.json';
import TopHeaderEs from '../../components/TopHeader/locales/default.es.json';

import RefundRulesPt from '../../components/RefundRules/locales/default.json';
import RefundRulesEn from '../../components/RefundRules/locales/default.en.json';
import RefundRulesEs from '../../components/RefundRules/locales/default.es.json';

pt['pt-BR'].home = homePt['pt-BR'];
en['en'].home = homeEn['en'];
es['es'].home = homeEs['es'];

pt['pt-BR'].aboutus = aboutusPt['pt-BR'];
en['en'].aboutus = aboutusEn['en'];
es['es'].aboutus = aboutusEs['es'];

pt['pt-BR'].affiliates = affiliatesPt['pt-BR'];
en['en'].affiliates = affiliatesEn['en'];
es['es'].affiliates = affiliatesEs['es'];

pt['pt-BR'].contact = contactPt['pt-BR'];
en['en'].contact = contactEn['en'];
es['es'].contact = contactEs['es'];

pt['pt-BR'].cookies = cookiesPt['pt-BR'];
en['en'].cookies = cookiesEn['en'];
es['es'].cookies = cookiesEs['es'];

pt['pt-BR'].commonQuestions = commonQuestionsPt['pt-BR'];
en['en'].commonQuestions = commonQuestionsEn['en'];
es['es'].commonQuestions = commonQuestionsEs['es'];

pt['pt-BR'].privacy = privacyPt['pt-BR'];
en['en'].privacy = privacyEn['en'];
es['es'].privacy = privacyEs['es'];

pt['pt-BR'].termsOfPurchase = termsOfPurchasePt['pt-BR'];
en['en'].termsOfPurchase = termsOfPurchaseEn['en'];
es['es'].termsOfPurchase = termsOfPurchaseEs['es'];

pt['pt-BR'].termsOfUse = termsOfUsePt['pt-BR'];
en['en'].termsOfUse = termsOfUseEn['en'];
es['es'].termsOfUse = termsOfUseEs['es'];

pt['pt-BR'].tour = tourPt['pt-BR'];
en['en'].tour = tourEn['en'];
es['es'].tour = tourEs['es'];

pt['pt-BR'].tourList = tourListPt['pt-BR'];
en['en'].tourList = tourListEn['en'];
es['es'].tourList = tourListEs['es'];

pt['pt-BR'].transfer = transferPt['pt-BR'];
en['en'].transfer = transferEn['en'];
es['es'].transfer = transferEs['es'];

// pt['pt-BR'].notFound = notFoundPt['pt-BR'];
// en['en'].notFound = notFoundEn['en'];
// es['es'].notFound = notFoundEs['es'];

pt['pt-BR'].footer = footerPt['pt-BR'];
en['en'].footer = footerEn['en'];
es['es'].footer = footerEs['es'];

pt['pt-BR'].cookieAlert = cookieAlertPt['pt-BR'];
en['en'].cookieAlert = cookieAlertEn['en'];
es['es'].cookieAlert = cookieAlertEs['es'];

pt['pt-BR'].navHeader = navHeaderPt['pt-BR'];
en['en'].navHeader = navHeaderEn['en'];
es['es'].navHeader = navHeaderEs['es'];

pt['pt-BR'].cart = cartHeaderPt['pt-BR'];
en['en'].cart = cartHeaderEn['en'];
es['es'].cart = cartHeaderEs['es'];

pt['pt-BR'].newUser = newUserPt['pt-BR'];
en['en'].newUser = newUserEn['en'];
es['es'].newUser = newUserEs['es'];

pt['pt-BR'].tabSearch = tabSearchPt['pt-BR'];
en['en'].tabSearch = tabSearchEn['en'];
es['es'].tabSearch = tabSearchEs['es'];

pt['pt-BR'].productOptions = productOptionsPt['pt-BR'];
en['en'].productOptions = productOptionsEn['en'];
es['es'].productOptions = productOptionsEs['es'];

// pt['pt-BR'].partners = partnersPt['pt-BR'];
// en['en'].partners = partnersEn['en'];
// es['es'].partners = partnersEs['es'];

// pt['pt-BR'].newsletter = newsletterPt['pt-BR'];
// en['en'].newsletter = newsletterEn['en'];
// es['es'].newsletter = newsletterEs['es'];

pt['pt-BR'].checkoutCards = CheckoutCardsPt['pt-BR'];
en['en'].checkoutCards = CheckoutCardsEn['en'];
es['es'].checkoutCards = CheckoutCardsEs['es'];

pt['pt-BR'].checkoutSteps = CheckoutStepsPt['pt-BR'];
en['en'].checkoutSteps = CheckoutStepsEn['en'];
es['es'].checkoutSteps = CheckoutStepsEs['es'];

pt['pt-BR'].productsCarrousel = ProductsCarrouselPt['pt-BR'];
en['en'].productsCarrousel = ProductsCarrouselEn['en'];
es['es'].productsCarrousel = ProductsCarrouselEs['es'];

pt['pt-BR'].productsReviews = ProductsReviewsPt['pt-BR'];
en['en'].productsReviews = ProductsReviewsEn['en'];
es['es'].productsReviews = ProductsReviewsEs['es'];

pt['pt-BR'].profile = ProfilePt['pt-BR'];
en['en'].profile = ProfileEn['en'];
es['es'].profile = ProfileEs['es'];

pt['pt-BR'].privacyTerms = PrivacyTermsNoticePt['pt-BR'];
en['en'].privacyTerms = PrivacyTermsNoticeEn['en'];
es['es'].privacyTerms = PrivacyTermsNoticeEs['es'];

// pt['pt-BR'].login = loginPt['pt-BR'];
// en['en'].login = loginEn['en'];
// es['es'].login = loginEs['es'];

pt['pt-BR'].modalPayment = modalPaymentPt['pt-BR'];
en['en'].modalPayment = modalPaymentEn['en'];
es['es'].modalPayment = modalPaymentEs['es'];

pt['pt-BR'].profilePass = ProfilePasswordPt['pt-BR'];
en['en'].profilePass = ProfilePasswordEn['en'];
es['es'].profilePass = ProfilePasswordEs['es'];

pt['pt-BR'].myActivities = MyActivitiesPt['pt-BR'];
en['en'].myActivities = MyActivitiesEn['en'];
es['es'].myActivities = MyActivitiesEs['es'];

pt['pt-BR'].myReservations = MyReservationsPt['pt-BR'];
en['en'].myReservations = MyReservationsEn['en'];
es['es'].myReservations = MyReservationsEs['es'];

pt['pt-BR'].modalConfirm = ModalConfirmPt['pt-BR'];
en['en'].modalConfirm = ModalConfirmEn['en'];
es['es'].modalConfirm = ModalConfirmEs['es'];

pt['pt-BR'].modalAlert = ModalAlertPt['pt-BR'];
en['en'].modalAlert = ModalAlertEn['en'];
es['es'].modalAlert = ModalAlertEs['es'];

pt['pt-BR'].topHeader = TopHeaderPt['pt-BR'];
en['en'].topHeader = TopHeaderEn['en'];
es['es'].topHeader = TopHeaderEs['es'];

pt['pt-BR'].refundRules = RefundRulesPt['pt-BR'];
en['en'].refundRules = RefundRulesEn['en'];
es['es'].refundRules = RefundRulesEs['es'];

export {
  pt,
  en,
  es,
};