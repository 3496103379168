import React from 'react';
import './PreLoader.scss';
import logo from '../../assets/img/c2tours.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function PreLoader() {
  return (
    <div className="App">
      <header className="App-header">
          <FontAwesomeIcon className="App-logo" size="6x" icon={['fal', 'spinner-third']}/>
        <p style={{marginTop: '40px'}}>
        <img src={logo} alt="C2SIG" className="float-left" style={{ width: '250px' }} />
        </p>
        
      </header>
    </div>
  );
}

export default PreLoader;