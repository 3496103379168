
function SetTransfersItem(item: any) {


  const getTransfers = localStorage.getItem("transferC2");
  let transfer = JSON.parse(getTransfers || '{}');
  transfer = item;
  localStorage.setItem("transferC2", JSON.stringify(transfer));  

  window.location.href="/transfer";
  return true;
}



export default SetTransfersItem;