import React from "react";
import ReactDOM from "react-dom";
import ScrollToTop from "./infra/ScrollToTop/ScrollToTop";

import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import TagManager from 'react-gtm-module';


const tagManagerArgs = {
    gtmId: String(process.env.REACT_APP_GTM),
    events: {
        add_to_cart: 'add_to_cart',
        dados: 'dados',
        pagamento: 'pagamento',
        cartaodecredito: 'cartaodecredito',
        pix: 'pix',
        purchase: 'purchase',
    }
}

if (process.env.NODE_ENV === 'development') {
    TagManager.initialize(tagManagerArgs);
} else {
    TagManager.initialize(tagManagerArgs);
}

ReactDOM.render(
    <BrowserRouter>
        <ScrollToTop />
        <App />
    </BrowserRouter>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
