/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";

import { useTranslation } from "react-i18next";

import api from "../../../../services/api";

import InputRange from "react-input-range";

import 'react-input-range/lib/css/index.css';

import "../FilterTour/FilterTour";

export interface prop {
    transfers: any;
    search: any;
    setSearch: any;
    setTransfers: any;
    setPageCount: any;
    setTotalRows: any;
    setLoading: any;
}

const FilterTransfers: React.FC<prop> = ({
    transfers, search, setSearch, setTransfers, setPageCount, setTotalRows, setLoading
}: prop) => {
    const [t] = useTranslation();
    const [filterVehicle, setFilterVehicle] = useState<any>([]);
    const [filterService, setFilterService] = useState<any>([]);
    const [filterCategory, setFilterCategory] = useState<any>([]);

    const [selectedFilterVehicle, setSelectedFilterVehicle] = useState<any>([]);
    const [selectedFilterService, setSelectedFilterService] = useState<any>([]);
    const [selectedFilterCategory, setSelectedFilterCategory] = useState<any>([]);

    const [minValue, setMinValue] = useState<any>(0);
    const [maxValue, setMaxValue] = useState<any>(1000);
    const [value, setValue] = useState<any>({ min: minValue, max: maxValue });

    useEffect(() => {
        const TransfersRender = async () => {
            try {
                const res = await api.post(
                    `${process.env.REACT_APP_SERVER_URL_API}/Products/FetchTransfersAsync`,
                    {
                        "lang": "BR",
                        "dateTransfer": `${search.date.split('/').reverse().join('-')}`,
                        "timeTransfer": `${search.time}`,
                        "numPeople": `${search.numPeople}`,
                        "origemLng": `${search.lngOrigem}`,
                        "origemLat": `${search.latOrigem}`,
                        "destinoLng": `${search.lngDestino}`,
                        "destinoLat": `${search.latDestino}`
                    }
                );
                if (res.status !== 400) {
                    console.log(res.data.data)
                    setFilterVehicle(res.data.data.vehicleTypeList);
                    setFilterService(res.data.data.serviceTypeList);
                    setFilterCategory(res.data.data.vehicleCategoryList);
                    setMinValue(res.data.data.minPrice);
                    setMaxValue(res.data.data.maxPrice);
                    setValue({ min: res.data.data.minPrice, max: res.data.data.maxPrice })

                }
            } catch (error) { 

            } finally {
                localStorage.setItem("transferC2", '[]');
            }
        };
        TransfersRender();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    useEffect(() => {
        console.log(search)
        setLoading(true);
        setPageCount(1);
        const TransfersRender = async () => {
            try {
                const res = await api.post(
                    `${process.env.REACT_APP_SERVER_URL_API}/Products/FetchTransfersAsync`,
                    {
                        "lang": "BR",
                        "dateTransfer": `${search.date.split('/').reverse().join('-')}`,
                        "timeTransfer": `${search.time}`,
                        "numPeople": `${search.numPeople}`,
                        "origemLng": `${search.lngOrigem}`,
                        "origemLat": `${search.latOrigem}`,
                        "destinoLng": `${search.lngDestino}`,
                        "destinoLat": `${search.latDestino}`,
                        "transferVehicleType": selectedFilterVehicle.length > 0 ? selectedFilterVehicle : '',
                        "transferServiceType": selectedFilterService.length > 0 ? selectedFilterService : '',
                        "vehicleCategory": selectedFilterCategory.length > 0 ? selectedFilterCategory : '',
                        "priceStart": value.min,
                        "priceEnd": value.max
                    }
                );
                if (res.status !== 400) {
                    if (res.data.statusCode === 204) {
                        setLoading(false);
                        setTransfers([]);

                    } else {
                        setLoading(false);
                        if (res.data.data.transferList.length > 0) {
                            setTransfers(res.data.data.transferList);
                            setTotalRows(res.data.data.transferList.length);
                        }
                    }
                }
            } catch (error) {
                setLoading(false);
            }
        };
        TransfersRender();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search, selectedFilterVehicle, selectedFilterService, selectedFilterCategory]);

    function handleRangeChange(e: any) {
        setValue(e);
    }

    /* Correção infinity */
    if (value.min === Infinity && value.max === -Infinity) {
        setMinValue(value.min = 0);
        setMaxValue(value.max = 1000);
    }
    /* Correção infinity */

    function handleSelectedFilterVehicle(elem: any) { ///cria o array dis checkbox selecionados para veículos
        var aux: any = []

        if (selectedFilterVehicle.includes(elem) === true) {
            for (let i = 0; i < selectedFilterVehicle.length; i++) {
                if (selectedFilterVehicle[i] !== elem) {
                    aux.push(selectedFilterVehicle[i]);
                }
            }
        } else {
            aux = [...selectedFilterVehicle, elem];
        }

        console.log(aux)
        setSelectedFilterVehicle(aux);
    }

    function handleSelectedFilterService(elem: any) {   ///cria o array dis checkbox selecionados para serviços
        var aux: any = []

        if (selectedFilterService.includes(elem) === true) {
            for (let i = 0; i < selectedFilterService.length; i++) {
                if (selectedFilterService[i] !== elem) {
                    aux.push(selectedFilterService[i]);
                }
            }
        } else {
            aux = [...selectedFilterService, elem];
        }

        console.log(aux)
        setSelectedFilterService(aux);
    }

    function handleSelectedFilterCategory(elem: any) {  ///cria o array dis checkbox selecionados para categoria
        var aux: any = []

        if (selectedFilterCategory.includes(elem) === true) {
            for (let i = 0; i < selectedFilterCategory.length; i++) {
                if (selectedFilterCategory[i] !== elem) {
                    aux.push(selectedFilterCategory[i]);
                }
            }
        } else {
            aux = [...selectedFilterCategory, elem];
        }

        console.log(aux)
        setSelectedFilterCategory(aux);
    }

    async function handleFilter() {
        setLoading(true);
        setPageCount(1);
        try {
            const res = await api.post(
                `${process.env.REACT_APP_SERVER_URL_API}/Products/FetchTransfersAsync`,
                {
                    "lang": "BR",
                    "dateTransfer": `${search.date.split('/').reverse().join('-')}`,
                    "timeTransfer": `${search.time}`,
                    "numPeople": `${search.numPeople}`,
                    "origemLng": `${search.lngOrigem}`,
                    "origemLat": `${search.latOrigem}`,
                    "destinoLng": `${search.lngDestino}`,
                    "destinoLat": `${search.latDestino}`,
                    "transferVehicleType": selectedFilterVehicle.length > 0 ? selectedFilterVehicle : '',
                    "transferServiceType": selectedFilterService.length > 0 ? selectedFilterService : '',
                    "vehicleCategory": selectedFilterCategory.length > 0 ? selectedFilterCategory : '',
                    "priceStart": value.min,
                    "priceEnd": value.max
                }
            );
            if (res.status !== 400) {
                if (res.data.statusCode === 204) {
                    setLoading(false);
                    setTransfers([]);
                } else {
                    setLoading(false);
                    if (res.data.data.transferList.length > 0) {
                        setTransfers(res.data.data.transferList);
                        setTotalRows(res.data.data.transferList.length);
                    }
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <>
            <Container className="pt-4" fluid style={{ margin: "5px" }}>
                <div className="mt-4 mb-5">
                    <h5 className="mb-4 title-filter">{t("transfer.price")}</h5>
                    <div className="d-flex m-auto mb-4">
                        <InputRange
                            minValue={minValue}
                            maxValue={maxValue}
                            value={value}
                            step={1}
                            onChange={handleRangeChange}
                            formatLabel={value => `R$${value}`}
                        />
                    </div>
                    <button className="btn btn-primary" onClick={handleFilter} style={{ borderRadius: "0", width: "96%" }}>{t("tourList.btnFilter")}</button>
                </div>
                <div className="mt-4 mb-5">
                    <h5>{t("transfer.vehicleType")}</h5>
                    <div id="type-vehicles" className="d-flex flex-column">
                        {filterVehicle.map((filter: any, index: any) => {
                            return (
                                <div key={index}>
                                    <div className="check-cat">
                                        <input type="checkbox" name="Arte" id={`vehicle-${index}`} onClick={() => { handleSelectedFilterVehicle(filter) }} />
                                        <label htmlFor={`vehicle-${index}`}>
                                            {filter}
                                        </label>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div className="mt-4 mb-5">
                    <h5>{t("transfer.kindOfService")}</h5>
                    <div className="d-flex flex-column">
                        {filterService.map((filter: any, index: any) => {
                            return (
                                <div key={index}>
                                    <div className="check-cat">
                                        <input type="checkbox" name="Arte" id={`service-${index}`} onClick={() => { handleSelectedFilterService(filter) }} />
                                        <label htmlFor={`service-${index}`}>
                                            {filter}
                                        </label>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div className="mt-4 mb-5">
                    <h5>{t("transfer.categoryType")}</h5>
                    <div className="d-flex flex-column">
                        {filterCategory.map((filter: any, index: any) => {
                            return (
                                <div key={index}>
                                    <div className="check-cat">
                                        <input type="checkbox" name="Arte" id={`category-${index}`} onClick={() => { handleSelectedFilterCategory(filter) }} />
                                        <label htmlFor={`category-${index}`}>{filter}</label>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
                {/* <div onClick={() => { setOpen(false) }}>
                        <button className="btn-secondary px-3 mb-4 w-50" onClick={handleClearFilter}>Limpar</button>
                        <button className="btn-primary px-3 mb-4 w-50" onClick={handleFilter}>Filtrar</button>
                    </div> */}
            </Container>
        </>
    );
}

export default FilterTransfers;
