import React, { useEffect, useState } from "react";

import api from "../../../../services/api";

import { Container, Row } from "react-bootstrap";
import FormAddress from "./components/FormAddress/FormAddress";
import FormBank from "./components/FormBank/FormBank";
import FormDocuments from "./components/FormDocuments/FormDocuments";
import FormPersonal from "./components/FormPersonal/FormPersonal";
import { useTranslation } from "react-i18next";

import './FormAffiliates.scss';
import PostShipping from "../../../../components/Modal/PostShipping/PostShipping";

function FormAffiliates() {
    const { t } = useTranslation();

    const [stepCtrl, setStepCtrl] = useState<number>(0);
    const [choice, setChoice] = useState<boolean>(false);

    const [supplierControlObject, setSupplierControlObject] = useState<any>();
    const [supplierObject, setSupplierObject] = useState<any>();

    /* Success Modal (Inserir) */
    const [modalSuccess, setModalSuccess] = useState<any>(false);
    const [responseText, setResponseText] = useState<any>();
    const [modalContent, setModalContent] = useState<any>();
    const [log, setLog] = useState<any>();
    /* END - Success Modal (Inserir) */

    /* Recaptcha */
    const [loading, setLoading] = useState<any>(false);
    const [isRecaptcha, setIsRecaptcha] = useState<any>(null);
    /* END - Recaptcha */

    const handleNext = (newObject: any) => {
        setSupplierObject(newObject);
        setStepCtrl(stepCtrl + 1);
    };

    //const groupId: any = localStorage.getItem("GroupId");

    const handleFinished = (objectFinished: any) => {
        //objectFinished.groupId = parseInt(groupId);
        sendAffiliates(objectFinished);
    }

    const sendAffiliates = async (obj: object) => {

        setModalContent("loading");
        try {
            const str = obj;
            const { data } = await api.post('/Affiliate/CreateAffiliateContractAsync', str);

            if (data.status !== 400) {
                setResponseText(data.data.texto);
                setLog(data.data.log);
                if(data.data.log === 0){
                    setModalContent("success");
                    setTimeout(() => {
                        window.location.reload();
                    }, 2500);
                }else{
                    setModalContent("error");
                }
            } else {
                setResponseText(data.data.texto);
                setLog(data.data.log);
                setModalContent("error");
                setLoading(true);
                setTimeout(() => {
                    setLoading(false);
                }, 50);
                setIsRecaptcha(null);
            }

        } catch (error: any) {
            if (error?.response?.status === 400) {
                setResponseText(error?.response?.data?.errorMessage || "Erro ao processar informações, entre em contato com o nosso suporte!");
                setLog(1);
                setModalContent("error");
                setIsRecaptcha(null);
                setLoading(true);
                setTimeout(() => {
                    setLoading(false);
                }, 50);
                setIsRecaptcha(null);
            }
        }
    }

    useEffect(() => {
        setSupplierControlObject((prevState: any) => {
            return { ...prevState, ...supplierObject };
        });
    }, [supplierObject]);

    useEffect(() => {
        if (stepCtrl >= 4) {
            handleFinished(supplierControlObject);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stepCtrl]);

    return (
        <>
            <Container className="steps-guide">
                <Row>
                    <div className="steps d-flex px-3 pb-1 px-md-0 col">
                        <div className="step complete">
                            1
                            <br />
                            <span className="complete">
                            {t("affiliates.personalData")}
                            </span>
                        </div>
                        <div
                            className={
                                stepCtrl > 0 ? "stepline complete" : "stepline "
                            }
                        ></div>
                        <div className={stepCtrl > 0 ? "step complete" : "step "}>
                            2
                            <br />
                            <span className={stepCtrl > 0 ? "complete" : ""}>
                            {t("affiliates.address")}
                            </span>
                        </div>
                        <div
                            className={
                                stepCtrl > 1 ? "stepline complete" : "stepline "
                            }
                        ></div>
                        <div className={stepCtrl > 1 ? "step complete" : "step "}>
                            3
                            <br />
                            <span className={stepCtrl > 1 ? "complete" : ""}>
                            {t("affiliates.documents")}
                            </span>
                        </div>
                        <div
                            className={
                                stepCtrl > 2 ? "stepline complete" : "stepline "
                            }
                        ></div>
                        <div className={stepCtrl > 2 ? "step complete" : "step "}>
                            4
                            <br />
                            <span className={stepCtrl > 2 ? "complete" : ""}>
                            {t("affiliates.bankData")}
                            </span>
                        </div>
                    </div>
                </Row>
                <div className="forms-affiliates">
                    {stepCtrl === 0 ? (
                        <FormPersonal
                            action={handleNext}
                            choice={setChoice}
                            supplierControlObject={supplierControlObject}
                        />
                    ) : (
                        "   "
                    )}
                    {stepCtrl === 1 ? (
                        <FormAddress
                            action={handleNext}
                            back={setStepCtrl}
                            supplierControlObject={supplierControlObject}
                        />
                    ) : (
                        " "
                    )}
                    {stepCtrl === 2 ? (
                        <FormDocuments
                            action={handleNext}
                            back={setStepCtrl}
                            choice={choice}
                            supplierControlObject={supplierControlObject}
                        />
                    ) : (
                        " "
                    )}
                    {stepCtrl > 2 ? (
                        <FormBank
                            action={handleNext}
                            setModalSuccess={setModalSuccess}
                            back={setStepCtrl}
                            supplierControlObject={supplierControlObject}
                            loading={loading}
                            isRecaptcha={isRecaptcha}
                            setIsRecaptcha={setIsRecaptcha}
                        />
                    ) : (
                        " "
                    )}
                </div>

                {/* Pós-envio */}
                <PostShipping
                    modalSuccess={modalSuccess}
                    setModalSuccess={setModalSuccess}
                    modalContent={modalContent}
                    log={log}
                    responseText={responseText}
                    redirectOnSuccess={'/agencias'}
                />
                {/* END - Pós-envio */}
            </Container>
        </>
    )
}

export default FormAffiliates;