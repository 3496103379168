import React from 'react';

import Form from 'react-bootstrap/Form';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import './Pagination.scss';

export interface propPage {
    totalRows: any;
    pageCount: any;
    setPageCount: any;
    rowsPerPage: any;
    setRowsPerPage: any;
    selectNumberRows: any;
}

const Pagination: React.FC<propPage> = ({
    totalRows, pageCount, setPageCount, rowsPerPage, setRowsPerPage, selectNumberRows
}: propPage) => {
    
    const paginateFoward = () => {
        setPageCount(pageCount + 1);
    }

    const paginateBack = () => {
        setPageCount(pageCount - 1);
    }

    function handleSelect(e: any) {
        setPageCount(1);
        setRowsPerPage(e.target.value);
    }

    return (
        <div className="d-flex justify-content-between">
            <div className="select-pagination">
                {
                    selectNumberRows === "yes"
                        ?
                        <Form.Select
                            onChange={handleSelect}
                            id="rowsPerPage"
                        >
                            <option value="10" selected={rowsPerPage === '10'}>10</option>
                            <option value="20" selected={rowsPerPage === '20'}>20</option>
                            <option value="30" selected={rowsPerPage === '30'}>30</option>
                        </Form.Select>
                        :
                        ""
                }
            </div>
            <div className="d-flex align-items-center">
                {
                    pageCount > 1
                        ?
                        <button className="paginate-btn" onClick={paginateBack}>{
                            <FontAwesomeIcon
                                icon={["fal", "chevron-left"]}
                                size="1x"
                                style={{ color: "#FFF", fontSize: "16px" }}
                            />
                        }</button>
                        :
                        ''
                }
                <span>Página {pageCount} - Mostrando ({(rowsPerPage * pageCount) - rowsPerPage} - {rowsPerPage * pageCount}) de {totalRows}</span>
                {
                    (totalRows > (rowsPerPage * pageCount))
                        ?
                        <button className="paginate-btn" onClick={paginateFoward}>{
                            <FontAwesomeIcon
                                icon={["fal", "chevron-right"]}
                                size="1x"
                                style={{ color: "#FFF", fontSize: "16px" }}
                            />
                        }
                        </button>
                        :
                        ''
                }
            </div>
        </div>
    )
}

export default Pagination;