import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const PrivateRoute: React.FC<{
    component: React.FC;
    path: string;
    exact: boolean;
    isAuthenticated: boolean;
}> = (props) => {
    if(props.isAuthenticated === true){
        const infoToken = JSON.parse(localStorage.getItem('c2siteAuth') || '{}');
        const now = new Date()
        let day: any = now.toISOString();

        if(infoToken?.token?.expireUTC < day){
            localStorage.removeItem('c2siteAuth')
        }
    }   
    return (
        
        <div>
            {
                props.isAuthenticated ?
                    (<Route path={props.path} exact={props.exact} component={props.component} />) :
                    (<Redirect to={`/login`} />)
            }
        </div>
    );
};

export default PrivateRoute;