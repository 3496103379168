import { useState, MouseEvent, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import AuthLogin from "../../Authenticated/AuthLogin/AuthLogin";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import BRFlag from "../../../assets/icons/brazil.svg";
import ESFlag from "../../../assets/icons/spain.svg";
import ENFlag from "../../../assets/icons/united-kingdom.svg";


// import ViewCart from "c2-cart";

import "./NavHeader.scss";
import CartHeader from "../../Cart/CartHeader";


const NavHeader = () => {
  const { t } = useTranslation();

  const [activeFlag] = useState<any>({ pt: BRFlag, en: ENFlag, es: ESFlag });
  // const [modalShow, setModalShow] = useState(false);
  const [lng, setLng] = useState(i18next.language);

  const changeLanguage = (e: MouseEvent<HTMLButtonElement>) => {
    var element = (e.target as HTMLButtonElement).value;
    setLng(element);
    
    i18next.changeLanguage(element);
  };

  const auth = localStorage.getItem('c2siteAuth') || '{}';

  function logOut() {
    localStorage.removeItem('c2siteAuth');

    if (window.location.pathname !== "/minhas-atividades") {
      window.location.reload();
    }

  }

  /* Detecta clique fora da div#wrapper para fechar o dropdown de login */
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  function useOutsideAlerter(ref: any) {
    useEffect(() => {
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          var elem: any = document.getElementById("authLoginDropdown");
          if (elem) {
            elem.classList.remove('active');
          }
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  /* //Detecta clique fora da div#wrapper para fechar o dropdown de login\\ */

  function keepDropdownOpen() {
    var elem: any = document.getElementById("authLoginDropdown");

    if (elem && !elem.classList.contains('active')) {
      elem.classList.add('active');
    }
  }

  return (
    <div className="navTabHome">
      <nav className="navbar navbar-expand navbar-light ">
        <div className="container flex-row p-0" style={{ maxHeight: "90px", flexWrap: 'nowrap' }}>
          {/* Logo Site */}
          {
            process.env.REACT_APP_ENVIRONMENT === "c2rio"
              ?
              <Link to="/" className="navbar-brand brand-c2">
                <img
                  alt="Logo C2Tours"
                  src={process.env.REACT_APP_SERVER_LOGO}
                  loading='lazy'
                ></img>
              </Link>
              :
              <Link to="/" className="navbar-brand brand-angra">
                <img
                  alt="Logo C2Tours"
                  src={process.env.REACT_APP_SERVER_LOGO}
                  loading='lazy'
                ></img>
              </Link>
          }
          {/* Logo Site */}

          {/* Flag Dropdown */}
          <div
            className="navbar-collapse d-none d-md-block"
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav ml-auto" style={{ maxHeight: "90px" }}>
              <li className="nav-item dropdown nav-item-c2">
                <span
                  className="nav-link text-primary text-center "
                  /* to="#" */
                  id="navbarDropdownLang"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <img
                    loading='lazy'
                    alt="language flag"
                    src={activeFlag[lng] === undefined ? activeFlag.pt : activeFlag[lng]}
                    style={{
                      width: "35px",
                      height: "35px",
                      display: "inline",
                    }}
                  />
                </span>

                <div
                  className="dropdown-menu languages px-4 py-3 rounded"
                  aria-labelledby="navbarDropdownLang"
                  data-dropdown="navbarDropdownMinhaConta"
                  style={{ zIndex: 1040 }}
                >
                  <button
                    className={lng === "pt" ? "pt active" : "pt"}
                    onClick={changeLanguage}
                    value="pt"
                  >
                    <img
                      loading='lazy'
                      id="port-lang"
                      alt="bandeira do brasil"
                      src={BRFlag}
                      style={{ width: "45px", height: "30px" }}
                    />
                    {t("navHeader.language.portugues")}
                  </button>
                  <button
                    className={lng === "en" ? "en active" : "en"}
                    onClick={changeLanguage}
                    value="en"
                  >
                    <img
                      loading='lazy'
                      id="esp-lang"
                      alt="bandeira da espanha"
                      src={ENFlag}
                      style={{ width: "45px", height: "30px" }}
                    />
                    {t("navHeader.language.ingles")}
                  </button>
                  <button
                    className={lng === "es" ? "es active" : "es"}
                    onClick={changeLanguage}
                    value="es"
                  >
                    <img
                      loading='lazy'
                      id="ingl-lang"
                      alt="bandeira do reino unido"
                      src={ESFlag}
                      style={{ width: "45px", height: "30px" }}
                    />
                    {t("navHeader.language.espanhol")}
                  </button>
                </div>
              </li>

              <li className="nav-item active nav-item-c2 d-none">
                <Link className="nav-link text-primary text-center" to="/">
                  <i className="fal fa-home fa-lg" aria-hidden="true"></i>
                </Link>
              </li>

              {/* Flag Dropdown */}

              {/* User Dropdown */}
              <li
                className="nav-item dropdown nav-item-c2 user-dropdown"
                data-nav="conta"
                onClick={keepDropdownOpen}
                ref={wrapperRef}
              >
                {auth === "{}" || auth === 'null' ?
                  <>
                    <span
                      className="nav-link text-primary text-center"
                      //to="#"
                      id="navbarDropdownMinhaConta"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <FontAwesomeIcon
                        icon={["fal", "user"]}
                        size="2x"
                        style={{ width: "20px" }}
                      />
                    </span>

                    <div
                      className="dropdown-menu my-account px-4 py-3 rounded"
                      id="authLoginDropdown"
                      aria-labelledby="navbarDropdownMinhaConta"
                      data-dropdown="navbarDropdownMinhaConta"
                      style={{ zIndex: 1040 }}
                    >
                      {/* <h4 className="text-primary">{t("navHeader.login.title")}</h4> */}
                      <h4 className="text-primary mb-3">{t("navHeader.login.subTitle")}</h4>
                      <AuthLogin />
                    </div>
                  </>

                  : <>
                    <span
                      className="nav-link text-primary text-center"
                      /* to="#" */
                      id="navbarDropdownMinhaConta"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {
                        JSON.parse(auth).userModel.photo === null || JSON.parse(auth).userModel.photo === "" || JSON.parse(auth).userModel.photo === undefined
                          ?
                          <FontAwesomeIcon
                            icon={['fad', 'user-circle']}
                            size="2x"
                            style={{ color: "white" }}
                          />
                          :
                          <i>
                            {/* <div className="loggedIn-profile-pic" style={{ backgroundImage: `url(${JSON.parse(auth).userModel.photo})` }}></div> */}
                            <img
                              loading='lazy'
                              src={JSON.parse(auth).userModel.photo}
                              className="loggedIn-profile-pic"
                              alt="icon user"
                            />
                          </i>
                      }
                    </span>

                    <div
                      className="dropdown-menu my-account logged-in px-4 py-3 rounded"
                      aria-labelledby="navbarDropdownMinhaConta"
                      data-dropdown="navbarDropdownMinhaConta"
                      style={{ zIndex: 1040 }}
                    >
                      <Link
                        to="/perfil"
                        style={{ display: "block", color: "black", textDecoration: "none", marginBottom: '0.375rem' }}
                      >
                        {t("navHeader.login.loggedInProfile")}
                      </Link>
                      <Link
                        to="/minhas-atividades"
                        style={{ display: "block", color: "black", textDecoration: "none" }}
                      >
                        {t("navHeader.login.myActivities")}
                      </Link>
                      <hr />
                      <Link
                        to="/"
                        style={{ display: "block", color: "black", textDecoration: "none" }}
                        onClick={logOut}
                      >
                        {t("navHeader.login.leave")}
                      </Link>

                    </div>
                  </>}

              </li>
              {/* User Dropdown */}

              {/* <CartHeader>*/}
              <li>
                <div className="icon-top d-flex align-items-center">
                  <CartHeader />
                </div>
              </li>
              {/* <CartHeader /> */}
              {/* <ViewCart> 
                            <FontAwesomeIcon
                                icon={["fal", "shopping-cart"]}
                                size="2x"
                                style={{ color: "#162c59", width: "24px" }}
                            /> 
                        </ViewCart>                        */}
              {/* Botão Menu */}
              {/*                         <button
                            className="navbar-toggler d-block d-md-none"
                            type="button"
                            data-toggle="collapse"
                            data-target="#navbarSupportedContent"
                            aria-controls="navbarSupportedContent"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                        >
                            <i className="">
                                <img
                                    width={50}
                                    height={65}
                                    src={iconBarsLarge}
                                    alt="icon-user"
                                />
                            </i>
                        </button> */}
              {/* Botão Menu */}
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default NavHeader;
