import React from "react";

import { Container, Row, Col } from "react-bootstrap";

/* import Header from "./components/HeaderAffiliates/HeaderAffiliates";
import Footer from "../../components/Footer/Footer"; */

import FormAffiliates from "./components/FormAffiliates/FormAffiliates";

import { Link as LinkRoll } from "react-scroll";

// import bgTop from "../../assets/img/banner-afiliado-destinow.jpg";
// import partner01 from "../../assets/img/partners-beto-carreiro.1ac4f820.png";
// import partner02 from "../../assets/img/download.png";
// import partner03 from "../../assets/img/partners-ginga-tropical.06635cab.png";
// import partner04 from "../../assets/img/partners-hot-beach-olimpia.1f9ee392.png";
// import partner05 from "../../assets/img/partners-rio-scenarium.f02a43a9.png";
// import partner06 from "../../assets/img/partners-rio-star.b11ce93e.png";
// import partnerC2 from "../../assets/img/partners/c2rio-parceiro.png";
// import partnerBD from "../../assets/img/partners/parque-bondinho-parceiro.png";
// import partnerRBT from "../../assets/img/partners/rio-bout-tour.png";
// import partnerPC from "../../assets/img/partners/paineiras-corcovado.png";
// import partnerTC from "../../assets/img/partners/trem-corcovado.png";
// import partnerSNOW from "../../assets/img/partners/snowland-gramado.png";
// import partnerAQUAMOTION from "../../assets/img/partners/aqua-motion-gramado.png";
// import partnerAQUARIO from "../../assets/img/partners/aqua-rio.png";
// import partnerBIO from "../../assets/img/partners/bio-parque-rio.png";
// import partnerRIOSTART from "../../assets/img/partners/rio-start.png";

// import handHolding from '../../assets/icons/afiliado/hand-holding-money.png';
// import dataAnalist from '../../assets/icons/afiliado/data-analist.png';
import dataStructure from '../../assets/icons/afiliado/data-structure.png';
import fingerSnap from '../../assets/icons/afiliado/finger-snap.png';
import handShaking from '../../assets/icons/afiliado/hand-shaking.png';
import helpDesk from '../../assets/icons/afiliado/help-desk.png';
//import lampGear from '../../assets/icons/afiliado/lamp-gear.png';
import megaphone from '../../assets/icons/afiliado/megaphone.png';


import afiliadoDesktopPt from '../../assets/img/afiliado/DesktopPt.png';
import afiliadoDesktopEn from '../../assets/img/afiliado/DesktopEn.png';
import afiliadoDesktopEs from '../../assets/img/afiliado/DesktopEs.png';

import afiliadoMobilePt from '../../assets/img/afiliado/MobilePt.png';
import afiliadoMobileEn from '../../assets/img/afiliado/MobileEn.png';
import afiliadoMobileEs from '../../assets/img/afiliado/MobileEs.png';

import afiliadoTabletPt from '../../assets/img/afiliado/TabletPt.png';
import afiliadoTabletEn from '../../assets/img/afiliado/TabletEn.png';
import afiliadoTabletEs from '../../assets/img/afiliado/TabletEs.png';

import "./Affiliates.scss";
import { Helmet } from "react-helmet";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import HeaderAffiliates from "./components/HeaderAffiliates/HeaderAffiliates";
import Footer from "../../components/Footer/Footer";

function Affiliates() {
  const { t } = useTranslation();

  const typeDevice:string = window.innerWidth >= 1025 ? "desktop" : window.innerWidth >= 506 && window.innerWidth <= 1024 ? "tablet" : "mobile";  

  return (
    <>
      <Helmet>
        <html lang={i18next.language} />
        <title>{`Afiliados | ${process.env.REACT_APP_SERVER_NAME}`}</title>
        <meta
          name="description"
          content="Participe do Programa de Afiliados Destinow e, com um portfólio de mais de 500 experiencias incluindo os principais atrativos com confirmações imediatas, encante o seu cliente trazendo agilidade e facilidade em suas compras."
        />
        <meta name="googlebot" content={process.env.REACT_APP_SERVER_ROBOTS} />

        {/* Essential META Tags */}
        <meta
          property="og:title"
          content={`Afiliados | ${process.env.REACT_APP_SERVER_NAME}`}
        />
        <meta property="og:type" content="TravelAgency" />
        <meta
          property="og:image"
          content={`${process.env.REACT_APP_SERVER_URL}${process.env.REACT_APP_SERVER_IMG_DEST}`}
        />
        <meta property="og:url" content={process.env.REACT_APP_SERVER_URL} />

        {/* Non-Essential, But Recommended */}
        <meta
          property="og:description"
          content="Participe do Programa de Afiliados Destinow e, com um portfólio de mais de 500 experiencias incluindo os principais atrativos com confirmações imediatas, encante o seu cliente trazendo agilidade e facilidade em suas compras."
        />
        <meta
          property="og:site_name"
          content={process.env.REACT_APP_SERVER_NAME}
        />

        {/* Non-Essential, But Required for Analytics */}
        {/* <meta property="fb:app_id" content="your_app_id" /> */}

        <meta name="robots" content={process.env.REACT_APP_SERVER_ROBOTS} />
        <link rel="canonical" href={process.env.REACT_APP_SERVER_URL} />
      </Helmet>

      <HeaderAffiliates />

      {/* <Header /> */}
      <LinkRoll
        activeClass="active"
        to="bg-form"
        spy={true}
        smooth={true}
        offset={-80}
        duration={0}
        //className="btn btn-primary"
      >
        <div
          className="bg-top-affiliates"
          style={
            typeDevice === "desktop"
              ? {
                  backgroundImage:
                    i18next.language === "pt" || i18next.language === "pt-BR"
                      ? `url(${afiliadoDesktopPt})`
                      : i18next.language === "en"
                      ? `url(${afiliadoDesktopEn})`
                      : `url(${afiliadoDesktopEs})`,
                }
              : typeDevice === "tablet"
              ? {
                  backgroundImage:
                    i18next.language === "pt" || i18next.language === "pt-BR"
                      ? `url(${afiliadoTabletPt})`
                      : i18next.language === "en"
                      ? `url(${afiliadoTabletEn})`
                      : `url(${afiliadoTabletEs})`,
                }
              : {
                  backgroundImage:
                    i18next.language === "pt" || i18next.language === "pt-BR"
                      ? `url(${afiliadoMobilePt})`
                      : i18next.language === "en"
                      ? `url(${afiliadoMobileEn})`
                      : `url(${afiliadoMobileEs})`,
                }
          }
        >
          {/* <Container className="container">
          <Row className="w-100">
            <Col md={8}>
              <h1>{t("affiliates.title")}</h1>
              <p>{t("affiliates.subtitle")}</p>
              <div>
                <LinkRoll
                  activeClass="active"
                  to="bg-form"
                  spy={true}
                  smooth={true}
                  offset={-80}
                  duration={0}
                  className="btn btn-primary"
                >
                  {t("affiliates.registerNow")}
                </LinkRoll>
              </div> */}
          {/* <button className="btn btn-primary">Registrar Agora</button> */}
          {/* </Col>
          </Row>
        </Container> */}
        </div>
      </LinkRoll>
      <div className="bg-meddium-affiliates">
        <Container className="container-content">
          <div className="w-100 d-flex justify-content-center">
            <h3>{t("affiliates.whyAffiliate")}</h3>
          </div>
          <div>
            <Row>
              <Col md={4}>
                <div className="reason">
                  <div>
                    {/* <FontAwesomeIcon
                      icon={["fal", ""]}
                      color="#EC421C"
                      style={{ height: "83px", fontSize: "70px" }}
                    /> */}
                    <img src={handShaking} alt="" className="img-filter" style={{ height: "83px", fontSize: "70px"}} />
                  </div>
                  <div className="pb-2 pt-3">
                    <b>{t("affiliates.whyAffiliateTitleReasonDestinations")}</b>
                  </div>
                  <div>
                    <p>
                      {t("affiliates.whyAffiliateNumberOne")}
                    </p>
                  </div>
                </div>
              </Col>
              {/* <Col md={4}>
                <div className="reason">
                  <div>
                    
                    <img src={handHolding} alt="" className="img-filter" style={{ height: "83px", fontSize: "70px"}} />
                  </div>
                  <div className="pb-2 pt-3">
                    <b>{t("affiliates.whyAffiliateTitleReasonComissions")}</b>
                  </div>
                  <div>
                    <p>
                      {t("affiliates.whyAffiliateEarnMoney")}
                    </p>
                  </div>
                </div>
              </Col> */}
              <Col md={4}>
                <div className="reason">
                  <div>
                    {/* <FontAwesomeIcon
                      icon={["fal", "shapes"]}
                      color="#EC421C"
                      style={{ height: "83px", fontSize: "70px" }}
                    /> */}
                    <img src={fingerSnap} alt="" className="img-filter" style={{ height: "83px", fontSize: "70px"}} />
                  </div>
                  <div className="pb-2 pt-3">
                    <b>{t("affiliates.whyAffiliateTitleReasonIntegration")}</b>
                  </div>
                  <div>
                    <p>
                      {t("affiliates.whyAffiliateFastEasy")}
                    </p>
                  </div>
                </div>
              </Col>

              <Col md={4}>
                <div className="reason">
                  <div>
                    {/* <FontAwesomeIcon
                      icon={["fal", "user-tie"]}
                      color="#EC421C"
                      style={{ height: "83px", fontSize: "70px" }}
                    /> */}
                    <img src={helpDesk} alt="" className="img-filter" style={{ height: "83px", fontSize: "70px"}} />
                  </div>
                  <div className="pb-2 pt-3">
                    <b>{t("affiliates.whyAffiliateTitleReasonSupport")}</b>
                  </div>
                  <div>
                    <p>{t("affiliates.whyAffiliateCommertialAgent")}</p>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              
              <Col md={4}>
                <div className="reason">
                  <div>
                    {/* <FontAwesomeIcon
                      icon={["fal", "badge-percent"]}
                      color="#EC421C"
                      style={{ height: "83px", fontSize: "70px" }}
                    /> */}
                    <img src={megaphone} alt="" className="img-filter" style={{ height: "83px", fontSize: "70px"}} />
                  </div>
                  <div className="pb-2 pt-3">
                    <b>{t("affiliates.whyAffiliateTitleReasonTools")}</b>
                  </div>
                  <div>
                    <p>{t("affiliates.whyAffiliateTools")}</p>
                  </div>
                </div>
              </Col>
              <Col md={4}>
                <div className="reason">
                  <div>
                    {/* <FontAwesomeIcon
                      icon={["fal", "chart-pie"]}
                      color="#EC421C"
                      style={{ height: "83px", fontSize: "70px" }}
                    /> */}
                    <img src={dataStructure} alt="" className="img-filter" style={{ height: "83px", fontSize: "70px"}} />
                  </div>
                  <div className="pb-2 pt-3">
                    <b>
                      {t("affiliates.whyAffiliateTitleReasonRealTimeReport")}
                    </b>
                  </div>
                  <div>
                    <p>
                      {t("affiliates.whyAffiliateSalesSupport")}
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
        {/* <div className="bg-partners-affiliates">
                    <Container className="container-content">
                        <div className="w-100 d-flex flex-column align-items-center pb-5">
                            <h3>
                                Nossos Parceiros
                            </h3>
                            <p>
                                Temos orgulho em trabalhar com as principais atrações brasileiras 
                            </p>
                        </div>
                        <Row style={{ display: "flex",  flexDirection: "row",  maxWidth: "1100px",  margin: "0 auto"}}>
                            <Col className="part-imgs mt-4">
                                <img src={`${partnerC2}`} alt="C2Rio Tour & Travel" />
                            </Col>
                            <Col className="part-imgs mt-4">
                                <img src={`${partnerBD}`} alt="Parque Bondinho do Pão de Açúcar" />
                            </Col>
                            <Col className="part-imgs mt-4">
                                <img src={`${partnerRBT}`} alt="Rio Bout Tour" />
                            </Col>
                            <Col className="part-imgs mt-4">
                                <img src={`${partnerPC}`} alt="Paineiras Corcovado" />
                            </Col>
                            <Col className="part-imgs mt-4">
                                <img src={`${partnerTC}`} alt="Trem do Corcovado" />
                            </Col>
                            <Col className="part-imgs mt-4">
                                <img src={`${partnerSNOW }`} alt="SnowLand Gramado" />
                            </Col>
                            <Col className="part-imgs mt-4">
                                <img src={`${partnerAQUAMOTION }`} alt="Aquamotion Gramado" />
                            </Col>
                            <Col className="part-imgs mt-4">
                                <img src={`${partnerAQUARIO }`} alt="AquaRio" />
                            </Col>
                            <Col className="part-imgs mt-4">
                                <img src={`${partnerBIO }`} alt="Bio Parque do Rio" />
                            </Col>
                            <Col className="part-imgs mt-4">
                                <img src={`${partnerRIOSTART }`} alt="Bio Parque do Rio" />
                            </Col>                            
                        </Row>
                    </Container>
                </div> */}
        <div id="bg-form" className="bg-form-affiliates">
          <Container className="container-content">
            <div className="w-100 d-flex flex-column align-items-center pb-5">
              <h3 className="pb-0">{t("affiliates.startTodayTitle")}</h3>
              <p>{t("affiliates.startTodaySubtitle")}</p>
            </div>
            <div>
              <FormAffiliates />
            </div>
          </Container>
        </div>
      </div>
      <div id="session-contacts">
        <Footer />
      </div>
    </>
  );
}

export default Affiliates;
