
import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import i18next from "i18next";
import { Container, Row } from "react-bootstrap";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./BannerApiOut.scss";
import { Link } from "react-router-dom";
// import api from "../../../services/api";

// import rioBoatTourDeskPt from "../../../assets/img/banners-rio-boat-julho/rio-boat-tour-julho.jpg";
// import rioBoatTourDeskEn from "../../../assets/img/banners-rio-boat-julho/rio-boat-tour-julho-en.jpg";
// import rioBoatTourDeskEs from "../../../assets/img/banners-rio-boat-julho/rio-boat-tour-julho-es.jpg";

// import rioBoatTourMobPt from "../../../assets/img/banners-rio-boat-julho/rio-boat-tour-julho-mobile.jpg";
// import rioBoatTourMobEn from "../../../assets/img/banners-rio-boat-julho/rio-boat-tour-julho-en-mobile.jpg";
// import rioBoatTourMobEs from "../../../assets/img/banners-rio-boat-julho/rio-boat-tour-julho-es-mobile.jpg";

import portalDoAgenteDeskPt from "../../../assets/img/BANNERS_API_OUT/PORTAL_DO_AGENTE/Banner Site Desktop.jpg";
import portalDoAgenteTabPt from "../../../assets/img/BANNERS_API_OUT/PORTAL_DO_AGENTE/Banner Site Tablet.jpg";
import portalDoAgenteMobPt from "../../../assets/img/BANNERS_API_OUT/PORTAL_DO_AGENTE/Banner Site Mobile.jpg";

import confortoSegurancaDeskPt from "../../../assets/img/BANNERS_API_OUT/CONFORTO_E_SEGURANCA_PILARES/PT/banner-site-desktop03.jpg";
import confortoSegurancaDeskEn from "../../../assets/img/BANNERS_API_OUT/CONFORTO_E_SEGURANCA_PILARES/EN/DESKTOP_QUAL_EN.jpg";
import confortoSegurancaDeskEs from "../../../assets/img/BANNERS_API_OUT/CONFORTO_E_SEGURANCA_PILARES/ES/DESKTOP_QUAL_ES.jpg";

import confortoSegurancaTabPt from "../../../assets/img/BANNERS_API_OUT/CONFORTO_E_SEGURANCA_PILARES/PT/banner-site-desktop03MEDIUM.jpg";
import confortoSegurancaTabEn from "../../../assets/img/BANNERS_API_OUT/CONFORTO_E_SEGURANCA_PILARES/EN/MEDIUM_QUAL_EN.jpg";
import confortoSegurancaTabEs from "../../../assets/img/BANNERS_API_OUT/CONFORTO_E_SEGURANCA_PILARES/ES/MEDIUM_QUAL_ES.jpg";

import confortoSegurancaMobPt from "../../../assets/img/BANNERS_API_OUT/CONFORTO_E_SEGURANCA_PILARES/PT/banner-site-desktop03MOBILE.jpg";
import confortoSegurancaMobEn from "../../../assets/img/BANNERS_API_OUT/CONFORTO_E_SEGURANCA_PILARES/EN/MOBILE_QUAL_EN.jpg";
import confortoSegurancaMobEs from "../../../assets/img/BANNERS_API_OUT/CONFORTO_E_SEGURANCA_PILARES/ES/MOBILE_QUAL_ES.jpg";

import receptivoDeskPt from "../../../assets/img/BANNERS_API_OUT/RECEPTIVO/PT/banner-site-desktop.jpg";
import receptivoDeskEn from "../../../assets/img/BANNERS_API_OUT/RECEPTIVO/EN/DESKTOP_RECE_EN.jpg";
import receptivoDeskEs from "../../../assets/img/BANNERS_API_OUT/RECEPTIVO/ES/DESKTOP_RECEP_ES.jpg";

import receptivoTabPt from "../../../assets/img/BANNERS_API_OUT/RECEPTIVO/PT/banner-site-desktopMEDIUM.jpg";
import receptivoTabEn from "../../../assets/img/BANNERS_API_OUT/RECEPTIVO/EN/MEDIUM_RECE_EN.jpg";
import receptivoTabEs from "../../../assets/img/BANNERS_API_OUT/RECEPTIVO/ES/MEDIUM_RECEP_ES.jpg";

import receptivoMobPt from "../../../assets/img/BANNERS_API_OUT/RECEPTIVO/PT/banner-site-desktopMOBILE.jpg";
import receptivoMobEn from "../../../assets/img/BANNERS_API_OUT/RECEPTIVO/EN/MOBILE_RECE_EN.jpg";
import receptivoMobEs from "../../../assets/img/BANNERS_API_OUT/RECEPTIVO/ES/MOBILE_RECEP_ES.jpg";

import transferDeskPt from "../../../assets/img/BANNERS_API_OUT/TRANSFERS/PT/banner-site-desktop04.jpg";
import transferDeskEn from "../../../assets/img/BANNERS_API_OUT/TRANSFERS/EN/DESKTOP_TRANS_EN.jpg";
import transferDeskEs from "../../../assets/img/BANNERS_API_OUT/TRANSFERS/ES/DESKTOP_TRANS_ES (1).jpg";

import transferTabPt from "../../../assets/img/BANNERS_API_OUT/TRANSFERS/PT/banner-site-desktop04MEDIUM.jpg";
import transferTabEn from "../../../assets/img/BANNERS_API_OUT/TRANSFERS/EN/MEDIUM_TRANS_EN.jpg";
import transferTabEs from "../../../assets/img/BANNERS_API_OUT/TRANSFERS/ES/MEDIUM_TRANS_ES.jpg";

import transferMobPt from "../../../assets/img/BANNERS_API_OUT/TRANSFERS/PT/banner-site-desktop04MOBILE.jpg";
import transferMobEn from "../../../assets/img/BANNERS_API_OUT/TRANSFERS/EN/MOBILE_TRANS_EN.jpg";
import transferMobEs from "../../../assets/img/BANNERS_API_OUT/TRANSFERS/ES/MOBILE_TRANS_ES.jpg";

import toursPrivativosDeskPt from "../../../assets/img/BANNERS_API_OUT/PASSEIOS_PRIVATIVOS/PT/banner-site-desktop02.jpg";
import toursPrivativosDeskEn from "../../../assets/img/BANNERS_API_OUT/PASSEIOS_PRIVATIVOS/EN/DESKTOP_TOUR_EN.jpg";
import toursPrivativosDeskEs from "../../../assets/img/BANNERS_API_OUT/PASSEIOS_PRIVATIVOS/ES/DESKTOP_TOUR_ES.jpg";

import toursPrivativosTabPt from "../../../assets/img/BANNERS_API_OUT/PASSEIOS_PRIVATIVOS/PT/banner-site-desktop02MEDIUM.jpg";
import toursPrivativosTabEn from "../../../assets/img/BANNERS_API_OUT/PASSEIOS_PRIVATIVOS/EN/MEDIUM_TOUR_EN.jpg";
import toursPrivativosTabEs from "../../../assets/img/BANNERS_API_OUT/PASSEIOS_PRIVATIVOS/ES/MEDIUM_TOUR_ES.jpg";

import toursPrivativosMobPt from "../../../assets/img/BANNERS_API_OUT/PASSEIOS_PRIVATIVOS/PT/banner-site-desktop02MOBILE.jpg";
import toursPrivativosMobEn from "../../../assets/img/BANNERS_API_OUT/PASSEIOS_PRIVATIVOS/EN/MOBILE_TOUR_EN.jpg";
import toursPrivativosMobEs from "../../../assets/img/BANNERS_API_OUT/PASSEIOS_PRIVATIVOS/ES/MOBILE_TOUR_ES.jpg";

import boatPrivativoDeskPt from "../../../assets/img/BANNERS_API_OUT/BOAT_PRIVATIVO/PT/desktop_ 1920x530px (4).jpg";
import boatPrivativoDeskEn from "../../../assets/img/BANNERS_API_OUT/BOAT_PRIVATIVO/EN/desktop_ 1920x530px_en (2).jpg";
import boatPrivativoDeskEs from "../../../assets/img/BANNERS_API_OUT/BOAT_PRIVATIVO/ES/desktop_ 1920x530px_es (2).jpg";

import boatPrivativoTabPt from "../../../assets/img/BANNERS_API_OUT/BOAT_PRIVATIVO/PT/tablet_ 1024x480px (4).jpg";
import boatPrivativoTabEn from "../../../assets/img/BANNERS_API_OUT/BOAT_PRIVATIVO/EN/tablet_ 1024x480px_en (2).jpg";
import boatPrivativoTabEs from "../../../assets/img/BANNERS_API_OUT/BOAT_PRIVATIVO/ES/tablet_ 1024x480px_es (2).jpg";

import boatPrivativoMobPt from "../../../assets/img/BANNERS_API_OUT/BOAT_PRIVATIVO/PT/mobile_ 768x640px (4).jpg";
import boatPrivativoMobEn from "../../../assets/img/BANNERS_API_OUT/BOAT_PRIVATIVO/EN/mobile_ 768x640px_en (2).jpg";
import boatPrivativoMobEs from "../../../assets/img/BANNERS_API_OUT/BOAT_PRIVATIVO/ES/mobile_ 768x640px_es (2).jpg";


export default function BannerApiOut() {
    const allBanners:any = {
        "pt": [
            {"image": [{image: portalDoAgenteDeskPt, link: "https://agents.c2rio.travel/home?utm_source=site&utm_medium=Banner&utm_campaign=Portal_Agent24", size: 'Desktop'}, {image: portalDoAgenteTabPt, link: "https://agents.c2rio.travel/home?utm_source=site&utm_medium=Banner&utm_campaign=Portal_Agent24", size: 'Medium'}, {image: portalDoAgenteMobPt, link: "https://agents.c2rio.travel/home?utm_source=site&utm_medium=Banner&utm_campaign=Portal_Agent24", size: 'Mobile'}]},
            {"image": [{image: confortoSegurancaDeskPt, link: "https://c2rio.travel/atividades/rio-de-janeiro?utm_source=Banner-Site&utm_medium=CPC_BannerSite&utm_campaign=Banner-Site_Pilares24", size: 'Desktop'}, {image: confortoSegurancaTabPt, link: "https://c2rio.travel/atividades/rio-de-janeiro?utm_source=Banner-Site&utm_medium=CPC_BannerSite&utm_campaign=Banner-Site_Pilares24", size: 'Medium'}, {image: confortoSegurancaMobPt, link: "https://c2rio.travel/atividades/rio-de-janeiro?utm_source=Banner-Site&utm_medium=CPC_BannerSite&utm_campaign=Banner-Site_Pilares24", size: 'Mobile'}]},
            {"image": [{image: receptivoDeskPt, link: "https://c2rio.travel/atividades/rio-de-janeiro?utm_source=Banner-Site&utm_medium=CPC_BannerSite&utm_campaign=Institucional_01_24", size: 'Desktop'}, {image: receptivoTabPt, link: "https://c2rio.travel/atividades/rio-de-janeiro?utm_source=Banner-Site&utm_medium=CPC_BannerSite&utm_campaign=Institucional_01_24", size: 'Medium'}, {image: receptivoMobPt, link: "https://c2rio.travel/atividades/rio-de-janeiro?utm_source=Banner-Site&utm_medium=CPC_BannerSite&utm_campaign=Institucional_01_24", size: 'Mobile'}]},
            {"image": [{image: toursPrivativosDeskPt, link: "https://grupos.c2rio.travel/cotacao?utm_source=Banner-Site&utm_medium=CPC_BannerSite&utm_campaign=Banner-Site_Privativos24", size: 'Desktop'}, {image: toursPrivativosTabPt, link: "", size: 'Medium'}, {image: toursPrivativosMobPt, link: "", size: 'Mobile'}]},
            {"image": [{image: transferDeskPt, link: "https://grupos.c2rio.travel/cotacao?utm_source=Banner-Site&utm_medium=CPC_BannerSite&utm_campaign=Banner-Site_Transfer24", size: 'Desktop'}, {image: transferTabPt, link: "https://grupos.c2rio.travel/cotacao?utm_source=Banner-Site&utm_medium=CPC_BannerSite&utm_campaign=Banner-Site_Transfer24", size: 'Medium'}, {image: transferMobPt, link: "https://grupos.c2rio.travel/cotacao?utm_source=Banner-Site&utm_medium=CPC_BannerSite&utm_campaign=Banner-Site_Transfer24", size: 'Mobile'}]},
            {"image": [{image: boatPrivativoDeskPt, link: "https://grupos.c2rio.travel/cotacao?utm_source=Banner-Site&utm_medium=CPC_BannerSite&utm_campaign=Banner-Site_Boat-privativo", size: 'Desktop'}, {image: boatPrivativoTabPt, link: "", size: 'Medium'}, {image: boatPrivativoMobPt, link: "", size: 'Mobile'}]}
    ],
        "en": [
            {"image": [{image: confortoSegurancaDeskEn, link: "https://c2rio.travel/atividades/rio-de-janeiro?utm_source=Banner-Site&utm_medium=CPC_BannerSite&utm_campaign=Banner-Site_Pilares24", size: 'Desktop'}, {image: confortoSegurancaTabEn, link: "https://c2rio.travel/atividades/rio-de-janeiro?utm_source=Banner-Site&utm_medium=CPC_BannerSite&utm_campaign=Banner-Site_Pilares24", size: 'Medium'}, {image: confortoSegurancaMobEn, link: "https://c2rio.travel/atividades/rio-de-janeiro?utm_source=Banner-Site&utm_medium=CPC_BannerSite&utm_campaign=Banner-Site_Pilares24", size: 'Mobile'}]},
            {"image": [{image: receptivoDeskEn, link: "https://c2rio.travel/atividades/rio-de-janeiro?utm_source=Banner-Site&utm_medium=CPC_BannerSite&utm_campaign=Institucional_01_24", size: 'Desktop'}, {image: receptivoTabEn, link: "https://c2rio.travel/atividades/rio-de-janeiro?utm_source=Banner-Site&utm_medium=CPC_BannerSite&utm_campaign=Institucional_01_24", size: 'Medium'}, {image: receptivoMobEn, link: "https://c2rio.travel/atividades/rio-de-janeiro?utm_source=Banner-Site&utm_medium=CPC_BannerSite&utm_campaign=Institucional_01_24", size: 'Mobile'}]},
            {"image": [{image: toursPrivativosDeskEn, link: "https://grupos.c2rio.travel/cotacao?utm_source=Banner-Site&utm_medium=CPC_BannerSite&utm_campaign=Banner-Site_Privativos24", size: 'Desktop'}, {image: toursPrivativosTabEn, link: "", size: 'Medium'}, {image: toursPrivativosMobEn, link: "", size: 'Mobile'}]},
            {"image": [{image: transferDeskEn, link: "https://grupos.c2rio.travel/cotacao?utm_source=Banner-Site&utm_medium=CPC_BannerSite&utm_campaign=Banner-Site_Transfer24", size: 'Desktop'}, {image: transferTabEn, link: "https://grupos.c2rio.travel/cotacao?utm_source=Banner-Site&utm_medium=CPC_BannerSite&utm_campaign=Banner-Site_Transfer24", size: 'Medium'}, {image: transferMobEn, link: "https://grupos.c2rio.travel/cotacao?utm_source=Banner-Site&utm_medium=CPC_BannerSite&utm_campaign=Banner-Site_Transfer24", size: 'Mobile'}]},
            {"image": [{image: boatPrivativoDeskEn, link: "https://grupos.c2rio.travel/cotacao?utm_source=Banner-Site&utm_medium=CPC_BannerSite&utm_campaign=Banner-Site_Boat-privativo", size: 'Desktop'}, {image: boatPrivativoTabEn, link: "", size: 'Medium'}, {image: boatPrivativoMobEn, link: "", size: 'Mobile'}]}
        ],
        "es": [
            {"image": [{image: confortoSegurancaDeskEs, link: "https://c2rio.travel/atividades/rio-de-janeiro?utm_source=Banner-Site&utm_medium=CPC_BannerSite&utm_campaign=Banner-Site_Pilares24", size: 'Desktop'}, {image: confortoSegurancaTabEs, link: "https://c2rio.travel/atividades/rio-de-janeiro?utm_source=Banner-Site&utm_medium=CPC_BannerSite&utm_campaign=Banner-Site_Pilares24", size: 'Medium'}, {image: confortoSegurancaMobEs, link: "https://c2rio.travel/atividades/rio-de-janeiro?utm_source=Banner-Site&utm_medium=CPC_BannerSite&utm_campaign=Banner-Site_Pilares24", size: 'Mobile'}]},
            {"image": [{image: receptivoDeskEs, link: "https://c2rio.travel/atividades/rio-de-janeiro?utm_source=Banner-Site&utm_medium=CPC_BannerSite&utm_campaign=Institucional_01_24", size: 'Desktop'}, {image: receptivoTabEs, link: "https://c2rio.travel/atividades/rio-de-janeiro?utm_source=Banner-Site&utm_medium=CPC_BannerSite&utm_campaign=Institucional_01_24", size: 'Medium'}, {image: receptivoMobEs, link: "https://c2rio.travel/atividades/rio-de-janeiro?utm_source=Banner-Site&utm_medium=CPC_BannerSite&utm_campaign=Institucional_01_24", size: 'Mobile'}]},
            {"image": [{image: toursPrivativosDeskEs, link: "https://grupos.c2rio.travel/cotacao?utm_source=Banner-Site&utm_medium=CPC_BannerSite&utm_campaign=Banner-Site_Privativos24", size: 'Desktop'}, {image: toursPrivativosTabEs, link: "https://grupos.c2rio.travel/cotacao?utm_source=Banner-Site&utm_medium=CPC_BannerSite&utm_campaign=Banner-Site_Privativos24", size: 'Medium'}, {image: toursPrivativosMobEs, link: "https://grupos.c2rio.travel/cotacao?utm_source=Banner-Site&utm_medium=CPC_BannerSite&utm_campaign=Banner-Site_Privativos24", size: 'Mobile'}]},
            {"image": [{image: transferDeskEs, link: "https://grupos.c2rio.travel/cotacao?utm_source=Banner-Site&utm_medium=CPC_BannerSite&utm_campaign=Banner-Site_Transfer24", size: 'Desktop'}, {image: transferTabEs, link: "https://grupos.c2rio.travel/cotacao?utm_source=Banner-Site&utm_medium=CPC_BannerSite&utm_campaign=Banner-Site_Transfer24", size: 'Medium'}, {image: transferMobEs, link: "https://grupos.c2rio.travel/cotacao?utm_source=Banner-Site&utm_medium=CPC_BannerSite&utm_campaign=Banner-Site_Transfer24", size: 'Mobile'}]},
            {"image": [{image: boatPrivativoDeskEs, link: "https://grupos.c2rio.travel/cotacao?utm_source=Banner-Site&utm_medium=CPC_BannerSite&utm_campaign=Banner-Site_Boat-privativo", size: 'Desktop'}, {image: boatPrivativoTabEs, link: "https://grupos.c2rio.travel/cotacao?utm_source=Banner-Site&utm_medium=CPC_BannerSite&utm_campaign=Banner-Site_Boat-privativo", size: 'Medium'}, {image: boatPrivativoMobEs, link: "https://grupos.c2rio.travel/cotacao?utm_source=Banner-Site&utm_medium=CPC_BannerSite&utm_campaign=Banner-Site_Boat-privativo", size: 'Mobile'}]}
        ],}
    

    const lang:string = i18next.language.slice(0, 2);
    const typeDevice = window.innerWidth < 480 ? "Mobile" : window.innerWidth >= 480 && window.innerWidth < 1024 ? "Medium" : "Desktop";

    const settings = {
        draggable: false,
        useTransform: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 1500,
        autoplaySpeed: 5500,
        cssEase: "ease-out",
    };

    return (
        <div className="bg-main-banner">
            <Slider {...settings}>
            {
                            allBanners[lang].map((bannerElem: any, bannerIndex: number) => {
                                let bannerSize: any = bannerElem.image.filter((elem: any) => elem.size === typeDevice)[0];
                                
                                //verifica se alguma imagem esta vazia
                                if (!bannerSize.image) return null;

                                return (
                                    <div>
                                        <Link
                                            to={{
                                                pathname: bannerSize.link,
                                            }}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <Container className="bg-secondary banner-home p-0 m-0" fluid>
                                                <Container className="p-0 m-0" fluid>
                                                    <Row className="p-0 m-0">
                                                        <img
                                                            className="p-0 m-0"
                                                            src={bannerSize.image}
                                                            alt={bannerSize.alt}
                                                            loading="lazy"
                                                        />
                                                    </Row>
                                                </Container>
                                            </Container>
                                        </Link>
                                    </div>
                                )
                            })
                        }

                {/* <Link
                    to={`tour/rio-boat-${i18next.language}`}
                >
                    <Container className="bg-secondary banner-home p-0 m-0" fluid>
                        <Container className="p-0 m-0" fluid>
                            <Row className="p-0 m-0">
                                {typeDevice === "Desktop" ? (
                                    <img
                                        className="p-0 m-0"
                                        src={
                                            i18next.language === "pt"
                                                ? rioBoatTourDeskPt
                                                : i18next.language === "en"
                                                    ? rioBoatTourDeskEn
                                                    : i18next.language === "es"
                                                        ? rioBoatTourDeskEs
                                                        : rioBoatTourDeskPt
                                        }
                                        alt="default banner"
                                        loading="lazy"
                                    />
                                ) : (
                                    <img
                                        className="p-0 m-0"
                                        src={
                                            i18next.language === "pt"
                                                ? rioBoatTourMobPt
                                                : i18next.language === "en"
                                                    ? rioBoatTourMobEn
                                                    : i18next.language === "es"
                                                        ? rioBoatTourMobEs
                                                        : rioBoatTourMobPt
                                        }
                                        alt="default banner"
                                        loading="lazy"
                                    />
                                )}
                            </Row>
                        </Container>
                    </Container>
                </Link> */}

                {/* <a
                 target="_blank"   href={`https://agents.c2rio.travel/home?utm_source=Banner-Site&utm_medium=cpc_banner&utm_campaign=Portal_Agente24`}
                >
                    <Container className="bg-secondary banner-home p-0 m-0" fluid>
                        <Container className="p-0 m-0" fluid>
                            <Row className="p-0 m-0">
                                {typeDevice === "Desktop" ? (
                                    <img
                                        className="p-0 m-0"
                                        src={portalDoAgenteDeskPt}
                                        alt="banner portal do agente"
                                        loading="lazy"
                                    />
                                ) : typeDevice === "Medium" ? (
                                    <img
                                        className="p-0 m-0"
                                        src={portalDoAgenteTabPt}
                                        alt="banner portal do agente"
                                        loading="lazy"
                                    />
                                ) : (
                                    <img
                                        className="p-0 m-0"
                                        src={portalDoAgenteMobPt}
                                        alt="banner portal do agente"
                                        loading="lazy"
                                    />
                                )}
                            </Row>
                        </Container>
                    </Container>
                </a>

                <a
                  target="_blank"  href={`https://c2rio.travel/atividades/rio-de-janeiro?utm_source=Banner-Site&utm_medium=CPC_BannerSite&utm_campaign=Banner-Site_Pilares24`}
                >
                    <Container className="bg-secondary banner-home p-0 m-0" fluid>
                        <Container className="p-0 m-0" fluid>
                            <Row className="p-0 m-0">
                                {typeDevice === "Desktop" ? (
                                    <img
                                        className="p-0 m-0"
                                        src={i18next.language === "pt"
                                                ? confortoSegurancaDeskPt
                                                : i18next.language === "en"
                                                    ? confortoSegurancaDeskEn
                                                    : i18next.language === "es"
                                                        ? confortoSegurancaDeskEs
                                                        : confortoSegurancaDeskPt}
                                        alt="Conforto e Segurança - Pilares"
                                        loading="lazy"
                                    />
                                ) : typeDevice === "Medium" ? (
                                    <img
                                        className="p-0 m-0"
                                        src={i18next.language === "pt"
                                                ? confortoSegurancaTabPt
                                                : i18next.language === "en"
                                                    ? confortoSegurancaTabEn
                                                    : i18next.language === "es"
                                                        ? confortoSegurancaTabEs
                                                        : confortoSegurancaTabPt}
                                        alt="Conforto e Segurança - Pilares"
                                        loading="lazy"
                                    />
                                ) : (
                                    <img
                                        className="p-0 m-0"
                                        src={i18next.language === "pt"
                                                ? confortoSegurancaMobPt
                                                : i18next.language === "en"
                                                    ? confortoSegurancaMobEn
                                                    : i18next.language === "es"
                                                        ? confortoSegurancaMobEs
                                                        : confortoSegurancaMobPt}
                                        alt="Conforto e Segurança - Pilares"
                                        loading="lazy"
                                    />
                                )}
                            </Row>
                        </Container>
                    </Container>
                </a>

                <a
                  target="_blank"  href={`https://c2rio.travel/atividades/rio-de-janeiro?utm_source=Banner-Site&utm_medium=CPC_BannerSite&utm_campaign=Institucional_01_24`}
                >
                    <Container className="bg-secondary banner-home p-0 m-0" fluid>
                        <Container className="p-0 m-0" fluid>
                            <Row className="p-0 m-0">
                                {typeDevice === "Desktop" ? (
                                    <img
                                        className="p-0 m-0"
                                        src={i18next.language === "pt"
                                                ? receptivoDeskPt
                                                : i18next.language === "en"
                                                    ? receptivoDeskEn
                                                    : i18next.language === "es"
                                                        ? receptivoDeskEs
                                                        : receptivoDeskPt}
                                        alt="banner receptivo 01"
                                        loading="lazy"
                                    />
                                ) : typeDevice === "Medium" ? (
                                    <img
                                        className="p-0 m-0"
                                        src={i18next.language === "pt"
                                                ? receptivoTabPt
                                                : i18next.language === "en"
                                                    ? receptivoTabEn
                                                    : i18next.language === "es"
                                                        ? receptivoTabEs
                                                        : receptivoTabPt}
                                        alt="banner receptivo 01"
                                        loading="lazy"
                                    />
                                ) : (
                                    <img
                                        className="p-0 m-0"
                                        src={i18next.language === "pt"
                                                ? receptivoMobPt
                                                : i18next.language === "en"
                                                    ? receptivoMobEn
                                                    : i18next.language === "es"
                                                        ? receptivoMobEs
                                                        : receptivoMobPt}
                                        alt="banner receptivo 01"
                                        loading="lazy"
                                    />
                                )}
                            </Row>
                        </Container>
                    </Container>
                </a>              
                
                <a
                  target="_blank"  href={`https://grupos.c2rio.travel/cotacao?utm_source=Banner-Site&utm_medium=CPC_BannerSite&utm_campaign=Banner-Site_Transfer24`}
                >
                    <Container className="bg-secondary banner-home p-0 m-0" fluid>
                        <Container className="p-0 m-0" fluid>
                            <Row className="p-0 m-0">
                                {typeDevice === "Desktop" ? (
                                    <img
                                        className="p-0 m-0"
                                        src={i18next.language === "pt"
                                                ? transferDeskPt
                                                : i18next.language === "en"
                                                    ? transferDeskEn
                                                    : i18next.language === "es"
                                                        ? transferDeskEs
                                                        : transferDeskPt}
                                        alt="banner transfer"
                                        loading="lazy"
                                    />
                                ) : typeDevice === "Medium" ? (
                                    <img
                                        className="p-0 m-0"
                                        src={i18next.language === "pt"
                                                ? transferTabPt
                                                : i18next.language === "en"
                                                    ? transferTabEn
                                                    : i18next.language === "es"
                                                        ? transferTabEs
                                                        : transferTabPt}
                                        alt="banner transfer"
                                        loading="lazy"
                                    />
                                ) : (
                                    <img
                                        className="p-0 m-0"
                                        src={i18next.language === "pt"
                                                ? transferMobPt
                                                : i18next.language === "en"
                                                    ? transferMobEn
                                                    : i18next.language === "es"
                                                        ? transferMobEs
                                                        : transferMobPt}
                                        alt="banner transfer"
                                        loading="lazy"
                                    />
                                )}
                            </Row>
                        </Container>
                    </Container>
                </a>
                
                <a
                  target="_blank"  href={`https://grupos.c2rio.travel/cotacao?utm_source=Banner-Site&utm_medium=CPC_BannerSite&utm_campaign=Banner-Site_Privativos24`}
                >
                    <Container className="bg-secondary banner-home p-0 m-0" fluid>
                        <Container className="p-0 m-0" fluid>
                            <Row className="p-0 m-0">
                                {typeDevice === "Desktop" ? (
                                    <img
                                        className="p-0 m-0"
                                        src={i18next.language === "pt"
                                                ? toursPrivativosDeskPt
                                                : i18next.language === "en"
                                                    ? toursPrivativosDeskEn
                                                    : i18next.language === "es"
                                                        ? toursPrivativosDeskEs
                                                        : toursPrivativosDeskPt}
                                        alt="banner tours privativos"
                                        loading="lazy"
                                    />
                                ) : typeDevice === "Medium" ? (
                                    <img
                                        className="p-0 m-0"
                                        src={i18next.language === "pt"
                                                ? toursPrivativosTabPt
                                                : i18next.language === "en"
                                                    ? toursPrivativosTabEn
                                                    : i18next.language === "es"
                                                        ? toursPrivativosTabEs
                                                        : toursPrivativosTabPt}
                                        alt="banner tours privativos"
                                        loading="lazy"
                                    />
                                ) : (
                                    <img
                                        className="p-0 m-0"
                                        src={i18next.language === "pt"
                                                ? toursPrivativosMobPt
                                                : i18next.language === "en"
                                                    ? toursPrivativosMobEn
                                                    : i18next.language === "es"
                                                        ? toursPrivativosMobEs
                                                        : toursPrivativosMobPt}
                                        alt="banner tours privativos"
                                        loading="lazy"
                                    />
                                )}
                            </Row>
                        </Container>
                    </Container>
                </a>

                  
                <a
                  target="_blank"  href={`https://grupos.c2rio.travel/cotacao?utm_source=Banner-Site&utm_medium=CPC_BannerSite&utm_campaign=Banner-Site_Boat-privativo`}
                >
                    <Container className="bg-secondary banner-home p-0 m-0" fluid>
                        <Container className="p-0 m-0" fluid>
                            <Row className="p-0 m-0">
                                {typeDevice === "Desktop" ? (
                                    <img
                                        className="p-0 m-0"
                                        src={i18next.language === "pt"
                                                ? boatPrivativoDeskPt
                                                : i18next.language === "en"
                                                    ? boatPrivativoDeskEn
                                                    : i18next.language === "es"
                                                        ? boatPrivativoDeskEs
                                                        : boatPrivativoDeskPt}
                                        alt="banner rio boat tour privativo"
                                        loading="lazy"
                                    />
                                ) : typeDevice === "Medium" ? (
                                    <img
                                        className="p-0 m-0"
                                        src={i18next.language === "pt"
                                                ? boatPrivativoTabPt
                                                : i18next.language === "en"
                                                    ? boatPrivativoTabEn
                                                    : i18next.language === "es"
                                                        ? boatPrivativoTabEs
                                                        : boatPrivativoTabPt}
                                        alt="banner rio boat tour privativo"
                                        loading="lazy"
                                    />
                                ) : (
                                    <img
                                        className="p-0 m-0"
                                        src={i18next.language === "pt"
                                                ? boatPrivativoMobPt
                                                : i18next.language === "en"
                                                    ? boatPrivativoMobEn
                                                    : i18next.language === "es"
                                                        ? boatPrivativoMobEs
                                                        : boatPrivativoMobPt}
                                        alt="banner rio boat tour privativo"
                                        loading="lazy"
                                    />
                                )}
                            </Row>
                        </Container>
                    </Container>
                </a> */}
            </Slider>
        </div>
    );
}