import { useState } from "react";

import { useTranslation } from "react-i18next";

import { Row } from "react-bootstrap";
import Slider from "react-slick";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./FilterTourCarrousel.scss";

function FilterTourCarrousel({ activeCarrousel }:any) {
    const [t] = useTranslation();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [nav2, setNav2] = useState<any>();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [slider1, setSlider1] = useState<any>();

    

    const settingsMain = {
        dots: false,
        arrows: true,
        infinite: false,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 3,
        responsive: [
            {
                breakpoint: 1025,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    function handleClick(index: any) {
        const active = document.querySelectorAll(".card-carrousel .card");
        /* console.log(active) */
        active[index].classList.toggle("active");
/*         var alo = teste;
        alo[index] = !alo[index];
        setTeste(alo);
        
        console.log(teste); */
    }

    return (
        <>
            <div className="card-carrousel px-1 px-md-0">
                <Slider
                    {...settingsMain}
                    asNavFor={nav2}
                    ref={(slider) => setSlider1(slider)}
                    className='unit'
                >
                    <label htmlFor="Arte, Design e Moda">
                        <div
                            className={(activeCarrousel !== undefined && activeCarrousel[0]) ? "card active" : "card"}
                            onClick={() => {
                                handleClick(0);
                            }}
                        >
                            <Row className="my-auto">
                                <div className="card-icon col-4 d-flex m-auto" >
                                    <FontAwesomeIcon
                                        icon={["fal", "palette"]}
                                        size="2x"
                                        style={{ fontSize: "28px" }}
                                    />
                                </div>
                                <div className="col-8 p-0 m-auto">{t("tourList.cat-1")}</div>
                            </Row>
                        </div>
                    </label>
                    <label htmlFor="História e Cultura">
                        <div
                            className={(activeCarrousel !== undefined && activeCarrousel[1]) ? "card active" : "card"}
                            onClick={() => {
                                handleClick(1);
                            }}
                        >
                            <Row className="my-auto">
                                <div className="card-icon col-4 d-flex m-auto">
                                    <FontAwesomeIcon
                                        icon={["fal", "landmark"]}
                                        size="2x"
                                        style={{ fontSize: "28px" }}
                                    />
                                </div>
                                <div className="col-8 p-0 m-auto">{t("tourList.cat-2")}</div>
                            </Row>
                        </div>
                    </label>
                    <label htmlFor="Estilo de Vida e Celebrações">
                        <div
                            className={(activeCarrousel !== undefined && activeCarrousel[2]) ? "card active" : "card"}
                            onClick={() => {
                                handleClick(2);
                            }}
                        >
                            <Row className="my-auto">
                                <div className="card-icon col-4 d-flex m-auto">
                                    <FontAwesomeIcon
                                        icon={["fal", "glass-cheers"]}
                                        size="2x"
                                        style={{ fontSize: "28px" }}
                                    />
                                </div>
                                <div className="col-8 p-0 m-auto">
                                    {t("tourList.cat-3")}
                                </div>
                            </Row>
                        </div>
                    </label>
                    <label htmlFor="Natureza e Impacto Social">
                        <div
                            className={(activeCarrousel !== undefined && activeCarrousel[3]) ? "card active" : "card"}
                            onClick={() => {
                                handleClick(3);
                            }}
                        >
                            <Row className="my-auto">
                                <div className="card-icon col-4 d-flex m-auto">
                                    <FontAwesomeIcon
                                        icon={["fal", "flower-tulip"]}
                                        size="2x"
                                        style={{ fontSize: "28px" }}
                                    />
                                </div>
                                <div className="col-8 p-0 m-auto">
                                    {t("tourList.cat-4")}
                                </div>
                            </Row>
                        </div>
                    </label>
                    <label htmlFor="Viagem">
                        <div
                            className={(activeCarrousel !== undefined && activeCarrousel[4]) ? "card active" : "card"}
                            onClick={() => {
                                handleClick(4);
                            }}
                        >
                            <Row className="my-auto">
                                <div className="card-icon col-4 d-flex m-auto">
                                    <FontAwesomeIcon
                                        icon={["fal", "luggage-cart"]}
                                        size="2x"
                                        style={{ fontSize: "28px" }}
                                    />
                                </div>
                                <div className="col-8 p-0 m-auto">{t("tourList.cat-5")}</div>
                            </Row>
                        </div>
                    </label>
                    <label htmlFor="Ar Livre e Aventura">
                        <div
                            className={(activeCarrousel !== undefined && activeCarrousel[5]) ? "card active" : "card"}
                            onClick={() => {
                                handleClick(5);
                            }}
                        >
                            <Row className="my-auto">
                                <div className="card-icon col-4 d-flex m-auto">
                                    <FontAwesomeIcon
                                        icon={["fal", "biking"]}
                                        size="2x"
                                        style={{ fontSize: "28px" }}
                                    />
                                </div>
                                <div className="col-8 p-0 m-auto">{t("tourList.cat-6")}</div>
                            </Row>
                        </div>
                    </label>
                </Slider>
            </div>
        </>
    );
}

export default FilterTourCarrousel;
