import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Partners01 from "../../assets/img/logo-abav.png";
import Partners02 from "../../assets/img/logo_cadastur.png";
// import i18next from "i18next";
import SafeTravels from "../../assets/img/safe-travels-c2rio.png";
import TourResp from "../../assets/img/turismo-responsavel-c2rio.png";
import TourConsc from "../../assets/img/turismo-consciente-c2rio.png";

import Elo from "../../assets/icons/elo-logo.png";
import Hiper from "../../assets/icons/Hipercard-logo.png";

import TripAdvisor from "../../assets/icons/svg/tripadvisor.svg";
import TrvlChoice2023 from "../../assets/img/trvl-choice-2023.png";
//import TripAdvisorGreen from "../../assets/icons/svg/selo-verde-trip.svg";
import VisitRioLogo from '../../assets/icons/visit_rio_logo.svg';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

import { Link } from "react-router-dom";
import "./footer.scss";

import MailchimpSubscribe from "react-mailchimp-subscribe";
import Newsletter from "./components/Newsletter/Newsletter";

import logoDestitech from "./../../assets/img/destitech.svg";

export default function Footer() {
  const postUrl = `https://c2rio.us13.list-manage.com/subscribe/post?u=1ab6480bde31e838a608aec2c&amp;id=d9d387ec11`;

  const [t] = useTranslation();


  return (
    <footer id="content-footer">
      <Container className="py-5 bg-footer" fluid>
        <Container>
          <Row>
            <Col md={4} className="pb-3 pb-md-3">
              <div className="list-footer">
                <p>
                  CNPJ: <span>{process.env.REACT_APP_INFO_CNPJ}</span>
                </p>
                <p style={{ width: "75%" }}>
                  <FontAwesomeIcon
                    icon={["fal", "map-marker-alt"]}
                    size="1x"
                    style={{ color: "#FFF", marginRight: "15px" }}
                  />
                  {/*<span>
                    {process.env.REACT_APP_INFO_ADDRESS}
                  </span>*/}
                  <p className={` mb-2`}>
                      <b>{t("footer.pontos")}</b>:  <br/>
                        <Link style={{ fontSize: '14px', lineHeight: '12px' }} 
                          target="_blank"
                          rel="noopener noreferrer"
                          to={{ pathname: 'https://www.google.com/maps/place/C2Rio+Tours+%26+Travel+-+Hilton+Copacabana+-+Ag%C3%AAncia+de+turismo+receptivo/@-22.9645179,-43.1734366,21z/data=!4m6!3m5!1s0x99810ce06e71fd:0x61a7e3a887742611!8m2!3d-22.9645921!4d-43.1733925!16s%2Fg%2F11hdd_sc5r?entry=ttu'}} >
                            <b>Hotel Hilton Copacabana</b> - Av. Atlântica, 1020 - Copacabana, Rio de Janeiro - RJ, 22010-000
                        </Link>
                  </p>
                  <p className={` mb-2`}>
                        <Link style={{ fontSize: '14px', lineHeight: '12px' }} 
                          target="_blank"
                          rel="noopener noreferrer"
                          to={{ pathname: 'https://www.google.com/maps/place/C2Rio+Tours+%26+Travel+-+Hotel+Hilton+Barra/@-22.9721817,-43.3750684,17z/data=!3m1!4b1!4m6!3m5!1s0x9bdb222b44b897:0x8ac802bf631ab2a7!8m2!3d-22.9721817!4d-43.3750684!16s%2Fg%2F11w2600kr9?entry=ttu://www.google.com/maps/dir//Hotel+Hilton+Barra+Rio+de+Janeiro+-+Avenida+Embaixador+Abelardo+Bueno+-+Barra+da+Tijuca,+Rio+de+Janeiro+-+RJ/@-22.9721781,-43.416242,13z/data=!3m1!5s0x9980006dd81ea5:0x98b6fc2c597d1aa3!4m8!4m7!1m0!1m5!1m1!1s0x9bd97dbd5bdfc9:0x9359f46c48704183!2m2!1d-43.3750424!2d-22.9722624?entry=ttu'}} >
                            <b>Hotel Hilton Barra</b> - Av. Embaixador Abelardo Bueno, 1430 - Barra da Tijuca, Rio de Janeiro - RJ, 22775-040
                        </Link>

                  </p>
                  <p className={` mb-2`}>
                        <Link style={{ fontSize: '14px', lineHeight: '12px' }} 
                          target="_blank"
                          rel="noopener noreferrer"
                          to={{ pathname: 'https://www.google.com/maps/dir//-22.9205411,-43.1705492/@-22.9205828,-43.1704247,20.54z/data=!4m5!4m4!1m0!1m1!4e1!3e0?entry=ttu'}} >
                            <b>PDV Boat</b> - Av. Infante Dom Henrique, S/N - Glória, Rio de Janeiro - RJ, 20021-140
                        </Link>

                  </p>
                </p>
                      <br/> 
                     
                     
                <p>
                  <FontAwesomeIcon
                    icon={["fal", "phone"]}
                    size="1x"
                    style={{ color: "#FFF", marginRight: "5px" }}
                  />{" "}
                  <span>
                    <a
                     href={`tel:${process.env.REACT_APP_INFO_PHONE}`}>
                      {process.env.REACT_APP_INFO_PHONE}
                    </a>
                  </span>
                </p>
                <p>
                  <FontAwesomeIcon
                    icon={["fab", "whatsapp"]}
                    size="1x"
                    style={{ color: "#FFF", marginRight: "5px" }}
                  />{" "}
                  <span>
                    <a
                      target="_blank"
                      href={`http://wa.me/552138281864`}
                    >
                      {process.env.REACT_APP_INFO_WHATSAPP}
                    </a>
                  </span>
                </p>
                
              </div>
              {/* <div className="py-3 mb-4 mb-md-0">
                <Link
                  to={{
                    pathname: "https://www.feefo.com/pt-BR/reviews/c2tours",
                  }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    alt="Feefo logo"
                    src="https://api.feefo.com/api/logo?merchantidentifier=c2tours&template=Service-Stars-White-150x38.png"
                  />
                </Link>
              </div> */}
            </Col>
            <Col md={4} className="pb-md-3">
              <div className="py-3 py-md-0">
                <h3 className="text-uppercase">{t("footer.support")}</h3>
                <ul className="list-footer">
                  <li>
                    <Link to="/termos-e-condicoes">{t("footer.termsCond")}</Link>
                  </li>
                  <li>
                    <Link to="/politica-de-privacidade">
                      {t("footer.policePrivacy")}
                    </Link>
                  </li>

                  <li>
                    <Link to="/cookies">{t("footer.cookie")}</Link>
                  </li>

                  <li>
                    <Link to={{ pathname: "https://portaldotitular.tah.group/titular_tah" }} target='_blank'>
                      {t("footer.portal")}
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="py-3 mb-4 mb-md-0">
                <h3 className="text-uppercase">{t("footer.help")}</h3>
                <ul className="list-footer">

                  {/*   -------------BLOG-------------
                  <li>
                    <Link
                      to={{ pathname: "https://blog.c2.tours/" }}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t("footer.blog")}
                    </Link>
                  </li> */}

                  <li>
                    <Link to="/sobre-nos">{t("footer.aboutUs")}</Link>
                  </li>
                  <li>
                    <Link to="/perguntas-frequentes">{t("footer.faq")}</Link>
                  </li>
                  {
                    process.env.REACT_APP_ENVIRONMENT === "c2rio"
                      ?
                      <>
                        <li>
                          <Link to="/fale-conosco">Fale conosco</Link>
                        </li>
                        <li>
                          <a href="https://agents.c2rio.travel/home" target="_blank" rel="noopener noreferrer">
                            {t("footer.partners")}
                          </a>
                        </li>
                      </>
                      :
                      <></>
                  }

                </ul>
              </div>
            </Col>
            <Col md={4} className="pb-md-3">
              <div className="mb-4 mb-md-0">
                <h3 className="text-uppercase">{t("footer.media")}</h3>
                <div className="icons-footer">
                  {
                    process.env.REACT_APP_ENVIRONMENT === "c2rio"
                      ?
                      <div className="py-2">
                        <Link
                          to={{ pathname: "https://www.tripadvisor.com.br/Attraction_Review-g303506-d9555526-Reviews-C2Rio_Tours_Travel-Rio_de_Janeiro_State_of_Rio_de_Janeiro.html" }}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img src={TripAdvisor} alt="selo tripadvisor" loading='lazy'
                            style={{ color: "#FFF", marginRight: "10px", height: "25px" }} />
                        </Link>
                      </div>
                      :
                      ""
                  }

                  <div className="py-2">
                    <Link
                      to={{ pathname: process.env.REACT_APP_LINK_INSTAGRAM }}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon
                        icon={["fab", "instagram"]}
                        size="2x"
                        style={{ color: "#FFF", marginRight: "15px" }}
                      />{" "}
                    </Link>
                  </div>
                  <div className="py-2">
                    <Link
                      to={{ pathname: process.env.REACT_APP_LINK_FACEBOOK }}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon
                        icon={["fab", "facebook-square"]}
                        size="2x"
                        style={{ color: "#FFF", marginRight: "15px" }}
                      />{" "}
                    </Link>
                  </div>
                  <div className="py-2">
                    <Link
                      to={{ pathname: process.env.REACT_APP_LINK_YOUTUBE }}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon
                        icon={["fab", "youtube"]}
                        size="2x"
                        style={{ color: "#FFF", marginRight: "15px" }}
                      />{" "}
                    </Link>
                  </div>
                  <div className="py-2">
                    <Link
                      to={{
                        pathname: process.env.REACT_APP_LINK_LINKEDIN,
                      }}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon
                        icon={["fab", "linkedin"]}
                        size="2x"
                        style={{ color: "#FFF", marginRight: "15px" }}
                      />{" "}
                    </Link>
                  </div>
                </div>
              </div>
              {/* ----------TRABALHE CONOSCO----------
              <div className="vagas py-3 mb-4 mb-md-0">
                <h3 className="text-uppercase">{t("footer.work")}</h3>
                <ul className="list-footer">
                  <li>
                    <Link to={{ pathname: "#" }}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t("footer.suppliers")}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={{
                        pathname: "https://www.linkedin.com/company/c2tours/jobs/",
                      }}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t("footer.jobs")}
                    </Link>
                  </li>
               </ul>
              </div> */}
              {
                process.env.REACT_APP_ENVIRONMENT === "c2rio"
                  ?
                  <div className="mb-5 mb-md-0 py-3">
                    <h3 className="text-uppercase">{t("footer.receive")}</h3>
                    <MailchimpSubscribe
                      url={postUrl}
                      render={({ subscribe, status, message }: any) => (
                        <Newsletter
                          status={status}
                          message={message}
                          onValidated={(formData: any) => subscribe(formData)}
                        />
                      )}
                    />
                  </div>
                  :
                  <></>
              }
            </Col>
            <hr />
          </Row>
          <Row className="d-flex align-items-center">
            <Col lg={4} md={6}>
              <Row>
                <Col xs={5} className="d-flex align-items-center">
                  <div className="partners mb-4 mb-md-0">
                    <img src={Partners01} alt="ícone Abav" loading='lazy' />
                  </div>
                </Col>
                <Col xs={7} className="d-flex align-items-center">
                  <div className="partners mb-4 mb-md-0">
                    <img className="w-100" src={Partners02} alt="ícone Cadastur" loading='lazy' />
                  </div>
                </Col>
              </Row>
            </Col>
            <Col lg={5} md={6} className="bg-partners-img">
              {
                process.env.REACT_APP_ENVIRONMENT === "c2rio"
                  ?
                  <Row className="d-flex align-items-center">
                    <Col xs={4}>
                      <div className="partners mb-4 mb-md-0 ">
                        <img src={VisitRioLogo} alt="Visit Rio LOGO" className="widCOEImg w-100" loading="lazy" id="CDSWIDCOELOGO" />
                      </div>
                    </Col>
                    {/* <Col xs={3}>
                      <div className="partners mb-4 mb-md-0 ">
                        <img src={TrvlChoice2023} alt="TripAdvisor" className="widCOEImg w-100" loading="lazy" id="CDSWIDCOELOGO" />
                      </div>
                    </Col>
                    <Col xs={3}>
                      <div className="partners mb-4 mb-md-0 ">
                        <img className="w-100" src={SafeTravels} alt="ícone Safe Travels" loading='lazy' />
                      </div>
                    </Col>
                    <Col xs={3}>
                      <div className="partners mb-4 mb-md-0 ">
                        <img className="w-100" src={TourResp} alt="ícone Turismo reponsavel" loading='lazy' />
                      </div>
                    </Col>
                    <Col xs={3}>
                      <div className="partners mb-4 mb-md-0 ">
                        <img className=" w-100" src={TourConsc} alt="ícone Turismo consciente" loading='lazy' />
                      </div>
                    </Col> */}
                  </Row>
                  :
                  ""
              }
            </Col>
            <Col lg={3} md={12}>
              <div style={{ marginTop: "10px" }} className="mb-4 mb-md-0">
                <h3 className="text-uppercase">{t("footer.safe")}</h3>
                <div className="icons-footer payment">
                  <FontAwesomeIcon
                    icon={["fab", "cc-visa"]}
                    size="2x"
                    style={{ color: "#FFF", width: "36px" }}
                  />
                  <FontAwesomeIcon
                    icon={["fab", "cc-mastercard"]}
                    size="2x"
                    style={{ color: "#FFF", width: "36px" }}
                  />
                  <FontAwesomeIcon
                    icon={["fab", "cc-diners-club"]}
                    size="2x"
                    style={{ color: "#FFF", width: "36px" }}
                  />
                  <FontAwesomeIcon
                    icon={["fab", "cc-amex"]}
                    size="2x"
                    style={{ color: "#FFF", width: "36px" }}
                  />
                  <img src={Elo} alt="ícone Elo Icon" loading='lazy' />
                  <img src={Hiper} alt="ícone Hiper Icon" loading='lazy' />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
      {
        process.env.REACT_APP_ENVIRONMENT === "c2rio"
          ?
          <Container className="bg-copyright" fluid>
            <Container className="d-flex flex-column flex-md-row justify-content-between">
              {/* Atualização da data ao passar de ano */}
              <div>
                <h3>Copyright © {new Date().getFullYear()} C2Rio Tours & Travel</h3>
              </div>
              {/* <div>
                <div className="d-flex justify-content-center justify-content-end gap-2">
                  <h3>Desenvolvido por:</h3>
                  <a href="https://destitech.com.br/" target="_blank" rel="noreferrer" className="d-flex">
                    <img src={logoDestitech} alt="Destitech" width="120px" />
                  </a>
                </div>
              </div> */}
            </Container>
          </Container>
          :
          <Container className="bg-copyright" fluid>
            <Container className="d-flex flex-column flex-md-row justify-content-between">
              {/* Atualização da data ao passar de ano */}
              <div>
                <h3>Copyright © {new Date().getFullYear()} Angra Way</h3>
              </div>
              <div>
                <div className="d-flex justify-content-center justify-content-end gap-2">
                  <h3>Desenvolvido por:</h3>
                  <a href="https://destitech.com.br/" target="_blank" rel="noreferrer" className="d-flex">
                    <img src={logoDestitech} alt="Destitech" width="120px" />
                  </a>
                </div>
              </div>
            </Container>
          </Container>
      }
    </footer>
  );
}
