import React, { useState } from "react";

import api from '../../../services/api';

import { Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useTranslation } from "react-i18next";
import Button from "@restart/ui/esm/Button";
import Modal from "react-bootstrap/Modal";
// import GoogleLogin from "react-google-login";
// import FacebookLogin from "react-facebook-login";

import '../../../components/Modal/ModalRegistration/ModalRegistration.scss';
import { Link } from "react-router-dom";
//import SimpleAlert from "../../Alert/SimpleAlert/SimpleAlert";

function Account() {
    const { t } = useTranslation();

    const [errorMessage, setErrorMessage] = useState<any>(null);
    const [loading, setLoading] = useState<any>(false);
    const [alertBool, setAlertBool] = useState<any>(false);
    const [alertMessage, setAlertMessage] = useState<any>("");

    /* const responseGoogle = (response: any) => {

        const { profileObj }: any = response;

        if (profileObj !== undefined) {

            const givenName = profileObj !== undefined ? profileObj.givenName : null;
            const familyName = profileObj !== undefined ? profileObj.familyName : null;
            const email = profileObj !== undefined ? profileObj.email : null;
            const googleId = profileObj !== undefined ? profileObj.googleId : null;
            const imageUrl = profileObj !== undefined ? profileObj.imageUrl : null;

            const getAuthenticateGoogleId = async () => {
                try {
                    const { data } = await api.post('/api/LoginSite/AccountCreate', { ///LOGIN API
                        "firstName": givenName,
                        "lastName": familyName,
                        "email": email,
                        "cellphone": "",
                        "password": "",
                        "googleId": googleId,
                        "facebookId": "",
                        "origem": 0,
                        "photo": imageUrl
                    });
                    if (data.status !== 400) {
                        localStorage.setItem("c2siteAuth", JSON.stringify(data.data));
                        if (window.location.pathname === "/login") {
                            window.location.href = "/";
                        } else if (data.data.log === 1) {
                            setAlertMessage(data.data.texto);
                            setAlertBool(true);
                        } else {
                            window.location.reload();
                        }
                    } else {

                    }

                } catch (error: any) {
                    setLoading(false)
                    if (error?.response?.status === 400) {
                        setErrorMessage(error.response.data.errorMessage)
                    }

                }
            }

            getAuthenticateGoogleId();
        }
    };

    const responseFacebook = (response: any) => {
        if (response.status === "unknown") {

        } else {
            const {
                name,
                email,
                id,
                picture: {
                    data: { url },
                },
            } = response;

            const getAuthenticateFacebookId = async () => {
                try {
                    const { data } = await api.post('/api/LoginSite/AccountCreate', { ///LOGIN API
                        "firstName": name.split(' ')[0],
                        "lastName": name.split(' ')[1],
                        "email": email,
                        "cellphone": "",
                        "password": "",
                        "googleId": "",
                        "facebookId": id,
                        "origem": 0,
                        "photo": url
                    });
                    if (data.status !== 400) {
                        localStorage.setItem("c2siteAuth", JSON.stringify(data.data));
                        if (window.location.pathname === "/login") {
                            window.location.href = "/";
                        } else if (data.data.log === 1) {
                            setAlertMessage(data.data.texto);
                            setAlertBool(true);
                        } else {
                            window.location.reload();
                        }
                    } else {

                    }

                } catch (error: any) {
                    setLoading(false)
                    if (error?.response?.status === 400) {
                        setErrorMessage(error.response.data.errorMessage)
                    }

                }
            }

            getAuthenticateFacebookId();

        }
    }; */

    const onSubmit = (e: any) => {
        e.preventDefault();

        const getAuthenticate = async () => {
            try {
                const { data } = await api.post('/LoginSite/Authenticate', { ///LOGIN API
                    "email": e.target.email.value,
                    "password": e.target.cust_pass.value,
                    "facebookId": '',
                    "googleId": '',
                });
                if (data.status !== 400) {
                    localStorage.setItem("c2siteAuth", data.data !== null ? JSON.stringify(data.data) : '{}');
                    if (data.statusCode === 400) {
                        setLoading(false)
                        setErrorMessage(data.errorMessage);
                    } else if (window.location.pathname === "/login") {
                        setLoading(false)
                        window.location.href = "/";
                    } else if (data.data.log === 1) {
                        setLoading(false)
                        setAlertMessage(data.data.texto);
                        setAlertBool(true);
                        /* alert(data.data.texto) */
                        setLoading(false)
                    } else {
                        setLoading(false)
                        window.location.reload();
                    }

                } else {
                    
                    setErrorMessage(data.data);
                }

            } catch (error: any) {
                setLoading(false)
                if (error?.response?.status === 400) {
                    setErrorMessage(error.response.data.data)
                }

            }
        }

        getAuthenticate();
    };

    // useEffect(() => {
    //     GetError();
    // }, [errorMessage]);

    // const GetError = () => {
    //     if (errorMessage === null) {
    //         return (
    //             <div className="form-group"></div>
    //         );
    //     } else {
    //         return (
    //             <div className="invalid form-group">
    //                 <div className="invalid-feedback">{errorMessage}</div>
    //             </div>
    //         );
    //     }
    // }

    return (
        <>
            <div className="modal-new-account">
                <form className="user" name="login_form" onSubmit={(e) => onSubmit(e)}>
                    <div className="form-group mb-2">
                        <label htmlFor="exampleInputEmail">{t("navHeader.login.email")}</label>
                        <input type="email" name="email" className="form-control" id="exampleInputEmail" aria-describedby="emailHelp" autoComplete="off" />
                    </div>
                    <div className="form-group mb-2 mb-md-3">
                        <label htmlFor="exampleInputPassword">{t("navHeader.login.password")}</label>
                        <input type="password" name="cust_pass" className="form-control" id="exampleInputPassword" autoComplete="off" />
                        {errorMessage === null ?
                            <></>
                            :
                            <div className="invalid form-group">
                                <div className="invalid-feedback text-small" style={{ display: 'block' }}>{errorMessage}</div>
                            </div>
                        }
                    </div>

                    <div className="form-group mb-1 mb-md-0">
                        <div className="custom-control custom-checkbox small pb-0 pb-md-2" style={{ display: "flex", alignItems: "center" }}>
                            <input type="checkbox" name="remember" className="form-check-input m-0" value="1" id="customCheck" style={{ width: "16px", height: "16px" }} />
                            <label className="custom-control-label px-2 m-0" htmlFor="customCheck" style={{ fontSize: "14px" }}>{t("navHeader.login.check")}</label>
                        </div>
                    </div>
                    <button type="submit" className="btn btn-primary btn-block w-100 login text-white" onClick={() => { setLoading(true) }} >{loading === true ? <div className="load small white"></div> : t("navHeader.login.submitButton")}</button>
                </form>

                <div className="mt-2 mt-md-3">
                    <Link className="forgot-link" to={'/esqueci-senha'}>{t("navHeader.login.forgotPass")}</Link>
                </div>


                <hr style={{ margin: "20px 0 " }} />

                {/* Media Social Mobile */}
                <Row className="d-flex">
                    {/* <div className="media-social col-6">
                        <FacebookLogin
                            appId="1063704611154644"
                            // bd6215aa6da5d6a66061adfbf2157c24
                            // 1b22b7bb9de29b3246f0cf8552ed7d7e
                            autoLoad={false}
                            fields="name,email,picture"
                            scope="public_profile,email,user_birthday"
                            icon="fal fa-facebook"
                            textButton={"Facebook"}
                            cssClass="btnFacebook"
                            callback={responseFacebook}
                        />
                    </div>
                    <div className="media-social col-6">
                        <GoogleLogin
                            clientId="275271754009-ro7mqtqnd1jlp0udcjc6io261m8qubre.apps.googleusercontent.com"
                            buttonText="Google"
                            onSuccess={responseGoogle}
                            onFailure={responseGoogle}
                        />
                    </div> */}
                    <small className="new-account-login mt-0 text-center" style={{ color: "#333333", fontSize: "14px" }}>{t("navHeader.login.DontHaveAccount")} <Link to={'/cadastrar-usuario'}><strong>{t("navHeader.login.CreateYourAccont")}</strong></Link></small>
                </Row>
                {/* Media Social Mobile */}
            </div>
            <Modal
                className="modal-confirm"
                show={alertBool}
                onHide={() => {
                    setAlertBool(false)
                }}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
            >
                <div className='modal-body text-center'>
                    <div>
                        <FontAwesomeIcon
                            icon={["fal", "times-circle"]}
                            size="5x"
                            style={{ fontSize: "7.5em", color: "#EC421C" }}
                        />

                    </div>
                    <div className="px-md-5" style={{ padding: "0 3rem" }}>
                        {alertMessage}
                    </div>
                    <div>
                        <Button type="submit" className="btn btn-primary form-button px-4 py-2" onClick={() => setAlertBool(false)}>Fechar</Button>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default Account;