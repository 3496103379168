import React from "react";
import DatePicker from "react-multi-date-picker"
import { useTranslation } from "react-i18next";
import configData from "../../config/config.json";

import './Calendar.scss';

const weekDays = ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"]
const months = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"]


const CustomMultipleInput = ({ openCalendar, value, setValue, defaultTime }: any) => {
  //let time: any = defaultTime?.split("-").reverse().split("/");
  const { t } = useTranslation();
  if (!value) {
    value = defaultTime;
  }

  setValue(value)

  return (
    <input
      className="form-control bg-white frm-sm py-2"
      onFocus={openCalendar}
      value={value}
      id="singleCalendarId"
      placeholder={t("affiliates.when")}
      readOnly
    />
  )
}

const SingleCalendarBirthday = (props: any) => {

  return (
    <DatePicker
      id="date-picker"
      weekDays={weekDays}
      months={months}
      format={configData.DATE.PT}
      maxDate={new Date(`${new Date().getFullYear() - 18}/${new Date().getMonth() + 1}/${new Date().getDate()}`)}
      render={
        <CustomMultipleInput
          setValue={props.setValue}
          defaultTime={props.defaultTime}
        />
      }
    />
  )
}

export default SingleCalendarBirthday;



