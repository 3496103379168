import React, { useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useTranslation } from "react-i18next";

import i18next from "i18next";

import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../../store/hooks";
import {
  removeItemCart,
  removeTotalCart,
  removeCupomCart,
} from "../../store/cart";

import "./CartHeader.scss";

function CartHeader() {
  const [t] = useTranslation();

  const lng =
    i18next.language === "pt"
      ? "BR"
      : i18next.language === "en"
        ? "EN"
        : i18next.language === "es"
          ? "ES"
          : "BR";

  const cartC2: any = JSON.parse(JSON.stringify(useAppSelector((state: any) => state.cart)));
  const dispatch = useAppDispatch();

  let totalCart = 0;

  var cartIcon: any = document.getElementById("navbarDropdownCarrinho");
  cartIcon?.addEventListener("mouseover", function (event: any) {
    var elem: any = document.getElementById("authCartDropdown");

    elem.classList.remove("d-none");

  })

  /* Detecta clique fora da div#wrapper para fechar o dropdown de login */
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  function useOutsideAlerter(ref: any) {
    useEffect(() => {
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          var elem: any = document.getElementById("authCartDropdown");
          if (elem) {
            elem.classList.remove("active");
          }
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  /* //Detecta clique fora da div#wrapper para fechar o dropdown de login\\ */

  function keepDropdownOpen(e: any) {
    var elem: any = document.getElementById("authCartDropdown");

    if (
      elem &&
      !elem.classList.contains("active") &&
      !e?.target?.classList.contains("close-btn")
    ) {
      elem.classList.remove("d-none");
      elem.classList.add("active");
    }
  }

  function keepDropdownClosed() {
    var elem: any = document.getElementById("authCartDropdown");

    if (elem && elem.classList.contains("active")) {
      elem.classList.remove("active");
    }

    elem.classList.add("d-none");
  }

  // const reload = ()=>{
  //   window.location.reload();
  // }



  if (cartC2.dados.length === 0) {
    //Carrinho vazio
    return (
      <>
        <ul className="navbar-nav ml-auto" id="wrapper" ref={wrapperRef}>
          <li
            className="dropdown carrinho nav-item-c2"
            data-cart="item"
            onClick={keepDropdownOpen}
          >
            <Link
              style={{ height: "90px" }}
              className="nav-link text-primary text-center"
              data-cart="btn"
              to="#"
              id="navbarDropdownCarrinho"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <FontAwesomeIcon
                icon={["fal", "shopping-cart"]}
                size="2x"
                style={{ width: "25px" }}
              />
              <div className="indicate-number">{cartC2.dados.length}</div>
            </Link>
            <div
              className="dropdown-menu cart"
              id="authCartDropdown"
              aria-labelledby="navbarDropdownCarrinho"
              data-cart="divMenu"
              style={{ zIndex: 1040 }}
            >
              <div className="titleCart bg-light d-flex justify-content-center align-items-center">
                {t("cart.cartHeader.emptyCart")}
              </div>
            </div>
          </li>
        </ul>
      </>
    );
  } else {
    return (
      <ul className="navbar-nav ml-auto" id="wrapper" ref={wrapperRef}>
        <li
          className="dropdown carrinho nav-item-c2"
          data-cart="item"
          onClick={keepDropdownOpen}
        >
          <Link
            style={{ height: "90px" }}
            className="nav-link text-primary text-center"
            data-cart="btn"
            to="#"
            id="navbarDropdownCarrinho"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <div className="d-flex">
              <FontAwesomeIcon
                icon={["fal", "shopping-cart"]}
                size="2x"
                style={{ width: "25px" }}
              />

              <div className="indicate-number">{cartC2.dados.length}</div>
              {/* <span style={{ marginRight: "8px", lineHeight: "28px" }}>Carrinho</span> */}
            </div>
            {/* <div className="d-md-inline-block" id="number-circle">
                            <span
                                id="numeroItens"
                                className="badge badge-pill badge-primary text-white"
                            >
                                { }
                            </span>
                        </div> */}
          </Link>
          <div
            className="dropdown-menu cart"
            id="authCartDropdown"
            aria-labelledby="navbarDropdownCarrinho"
            data-cart="divMenu"
            style={{ zIndex: 1040 }}
          >
            <div className="titleCart bg-light d-flex space-between align-items-center">
              <p>
                <strong>{t("cart.cartHeader.cart")}</strong> {cartC2.dados.length} {t("cart.cartHeader.item")}
              </p>{" "}
              <b
                className="close-btn"
                style={{ fontSize: "1.375rem", cursor: "pointer" }}
                onClick={keepDropdownClosed}
              >
                ×
              </b>
            </div>
            <div className="messages d-none">{t("cart.cartHeader.addedToCart")}</div>
            <div className="listItens">
              {cartC2.dados.length >= 1 ? (
                cartC2.dados.map((cItem: any, index: any) => {
                  let priceProduct =
                    Number(cItem.adults) * Number(cItem.priceAdults) +
                    Number(cItem.childs) * Number(cItem.priceChilds) +
                    Number(cItem.infants) * Number(cItem.priceInfants) +
                    Number(cItem.elders) * Number(cItem.priceElders) +
                    Number(cItem.student) * Number(cItem.priceStudent)
                   + Number(cItem.priceGlobalPeople);
                  
                  totalCart =
                    Number(totalCart) +
                    (priceProduct - priceProduct * (cItem.discount / 100));
                  return (
                    <div
                      className="lineItemCart border-bottom row m-0"
                      key={index}
                    >
                      <div
                        className="col-4 p-0"
                        style={{ position: "relative" }}
                      >
                        <div className="controlImage">
                          <img
                            src={
                              cItem.productType === 1
                                ? cItem.imagesBaseUrl +
                                cItem.imgCart
                                : cItem.productType === 4
                                  ? cItem.imagesBaseUrl +
                                  cItem.imgCart
                                  : cItem.imagesBaseUrl + cItem.imgCart
                            }
                            className="card-img"
                            alt={`cart-product-${index}`}
                            loading='lazy'
                          />
                        </div>
                      </div>
                      <div className="col-8 controlInfo">
                        <div className="infoCart">
                          <h6
                            title={`${cItem.productName}`}
                            className="titleItem"
                          >
                            {cItem.productName}
                          </h6>
                          <div className="d-flex space-between">
                            <div>
                              {t("cart.cartHeader.date")}{" "}
                              {String(cItem.date)
                                .split("-")
                                .reverse()
                                .join("/")}
                            </div>
                            <div>
                              {
                                parseInt(cItem.productType || 0) === 2
                                  ?
                                  <>
                                    {cItem.globalPeople}{`${t("cart.cartHeader.people")}`}
                                  </>
                                  :
                                  <>
                                    {
                                      cItem.sellingType === 2
                                        ?
                                        (Number(cItem.adults) + Number(cItem.childs) + Number(cItem.infants) + Number(cItem.elders) + Number(cItem.student)) + `${t("cart.cartHeader.people")}`
                                        :
                                        cItem.sellingType === 1
                                          ?
                                          (Number(cItem.totalPeople)) + `${t("cart.cartHeader.people")}`
                                          :
                                          (Number(cItem.adults) + Number(cItem.childs) + Number(cItem.infants) + Number(cItem.elders) + Number(cItem.student)) + `${t("cart.cartHeader.people")}`
                                    }
                                  </>
                              }
                              {
                                (Number(cItem.adults) + Number(cItem.childs) + Number(cItem.infants) + Number(cItem.elders) + Number(cItem.student)) > 1 || cItem.globalPeople > 1
                                  ?
                                  lng !== "EN"
                                    ?
                                    <>
                                      {'s'}
                                    </>
                                    :
                                    <></>
                                  :
                                  <></>
                              }
                            </div>
                          </div>
                        </div>
                        <div className="priceControl d-flex space-between">
                          <div className="d-flex">
                            <small>R$</small>{" "}
                            <div className="priceItem ml-1">
                              {(
                                priceProduct/* -
                                priceProduct * (cItem.discount / 100) */
                              )
                                .toFixed(2)
                                .split(".")
                                .join(",")}
                            </div>
                          </div>
                          <div>
                            <FontAwesomeIcon
                              icon={["fal", "trash-alt"]}
                              style={{ cursor: "pointer" }}
                              className="text-primary p-1"
                              size="lg"
                              onClick={() => {
                                dispatch(removeItemCart(cItem));
                               //reload();
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <></>
              )}
            </div>
            <div className="subTotalControl bg-light">
              {cartC2?.cupom?.type === 2 ? (
                <>
                  <div className="bg-cupom-value">
                    <small className="d-flex space-between align-items-center">
                      <p className="mb-0">{cartC2.cupom.code}</p>
                    </small>
                    <small className="text-right">
                      <span className="mb-0">
                        {cartC2.cupom.value.toFixed(2).split(".").join(",")}
                      </span>
                      <span
                        className="exclude-cupom"
                        onClick={() => dispatch(removeCupomCart())}
                      >
                        <FontAwesomeIcon
                          icon={["fal", "times"]}
                          size="1x"
                          style={{ marginLeft: "20px" }}
                        />
                      </span>
                    </small>
                  </div>
                  <div className="bg-cart-price-total pt-0">
                    <div>
                      <p>{t("cart.cartHeader.subtotal")}</p>
                    </div>
                    <div>
                      <p>
                        R$
                        <b>
                          {
                            cartC2.totalCart
                              .toLocaleString("pt-br", {
                                style: "currency",
                                currency: "BRL",
                              })
                              .split("R$")[1]
                          }
                        </b>
                      </p>
                    </div>
                  </div>
                </>
              ) : cartC2?.cupom?.type === 4 ? (
                <>
                  <small className="d-flex space-between align-items-center">
                    <p className="mb-0">{cartC2.cupom.code}</p>
                    <p className="mb-0">{cartC2.cupom.value}%</p>
                  </small>
                  <small className="text-right">
                    <p
                      className="exclude-cupom"
                      onClick={() => dispatch(removeCupomCart())}
                    >
                      <FontAwesomeIcon
                        icon={["fal", "times"]}
                        size="1x"
                        style={{ marginLeft: "20px" }}
                      />
                    </p>
                  </small>
                  <div className="bg-cart-price-total pt-0">
                    <div>
                      <p>{t("cart.cartHeader.subtotal")}</p>
                    </div>
                    <div>
                      <p>
                        R$
                        <b>
                          {
                            cartC2.totalCart
                              .toLocaleString("pt-br", {
                                style: "currency",
                                currency: "BRL",
                              })
                              .split("R$")[1]
                          }
                        </b>
                      </p>
                    </div>
                  </div>
                </>
              ) : (
                <div className="bg-cart-price-total">
                  <div>
                    <p>{t("cart.cartHeader.subtotal")}</p>
                  </div>
                  <div>
                    <p>
                      R$
                      <b>
                        {
                          cartC2.totalCart?.toLocaleString("pt-br", {
                              style: "currency",
                              currency: "BRL",
                            })
                            .split("R$")[1]
                        }
                      </b>
                    </p>
                  </div>
                </div>
              )}
            </div>
            <div className="p-3 bg-primary-dark">
              <div className="d-flex space-between">
                <Button                  
                  type="button"
                  className="btn-cart"
                  variant=" btn-outline-light"
                  onClick={() => {
                    dispatch(removeTotalCart(cartC2.dados));
                    //reload();
                  }}
                >
                  {t("cart.cartHeader.clearCart")}
                </Button>

                <Link to="/checkout" className="btn btn-primary btn-cart">
                  {t("cart.cartHeader.buyNow")}
                </Link>
              </div>
              <div className="cart-cancel">
                <h6 className="text-white">{t("cart.cartHeader.freeCancellation")}</h6>
                <small className="text-white">
                  {t("cart.cartHeader.twentyFourHours")}
                </small>
              </div>
            </div>
          </div>
        </li>
      </ul>
    );
  }
}

export default CartHeader;
