
import { useEffect } from 'react';
import './WhatsAppWidget.scss';
const WhatsAppWidget = () => {


    return(
        <div className="whatsapp-widget-container">
        <iframe
            className="whatsapp-widget-iframe"
            width="64"
            height="64"
            style={{ border: "0" }}
            src="https://cdn.smooch.io/message-us/index.html?channel=whatsapp&color=green&size=standard&radius=20px&label=&number=552138281864">
        </iframe>
        </div>
    )
}

export default WhatsAppWidget;